import React, { useState, useEffect, useMemo } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import "./App.css";
import Cookies from "universal-cookie";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import { AdminContext } from "./AdminContext";
import { AlertContext } from "./components/Alert/AlertContext";
import { CurrentCampaignIdContext } from "./components/Context/CurrentCampaignId";
import CreateTwitterCampaign from "./pages/CreateTwitterCampaign";
import Navbar from "./components/Navbar/Navbar";
import TweetsCampaign from "./pages/TweetsCampaign";
import TweetsCampaignFromTweetID from './pages/TweetsCampaignFromTweetID';
import Archives from "./pages/Archives";
import Donate from "./pages/Donate";
import Admin from "./pages/Admin";
import CreateIncident from "./components/Archives/CreateIncident";
import CreateVideoIncident from "./components/Archives/CreateVideoIncident";
import CreateArticleIncident from "./components/Archives/CreateArticleIncident";
import CreateImageIncident from "./components/Archives/CreateImageIncident";
import EmailsCampaign from "./pages/EmailsCampaign";
import CreateEmailCampaign from "./pages/CreateEmailCampaign";
import { PrivateRoute } from "./components/PrivateRoute";
import AddTweetsToCurrentCampaignPage from "./components/User/AddTweetsToCurrentCampaignPage";
import Payment from "./components/Payment";

export default function App() {
  const cookies = new Cookies();
  const [currentCampaignId, setCurrentCampaignId] = useState();
  const [isAdminAuth, setIsAdminAuth] = useState(false);
  const [showConfirmDeleteModel, setShowConfirmDeleteModel] = useState(false);

  useEffect(() => {
    setIsAdminAuth(cookies.get("isAuth") === "true" ? true : false);
  }, []);

  const userProviderValue = useMemo(
    () => ({ isAdminAuth, setIsAdminAuth }),
    [isAdminAuth, setIsAdminAuth]
  );

  return (
    <div className="app">
      <Router>
        <AdminContext.Provider value={userProviderValue}>
          <AlertContext.Provider
            value={{
              showConfirmDeleteModel,
              setShowConfirmDeleteModel,
            }}
          >
            <CurrentCampaignIdContext.Provider
              value={{
                currentCampaignId,
                setCurrentCampaignId,
              }}
            >
              <Navbar />
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="/twitter/:twitter_campaign_id"> 
                <TweetsCampaignFromTweetID/>
                </Route>
                <Route exact path="/twitter">
                  <TweetsCampaign />
                </Route>
                <Route path="/email">
                  <EmailsCampaign />
                </Route>
                <Route path="/archives">
                  <Archives />
                </Route>
                <Route exact path="/payment">
                  <Payment />
                </Route>
                <Route exact path="/donate">
                  <Donate />
                </Route>
                <Route exact path="/SignIn">
                  <SignIn />
                </Route>
                <Route exact path="/SignUp">
                  <SignUp />
                </Route>
                <PrivateRoute
                  component={AddTweetsToCurrentCampaignPage}
                  exact
                  path="/addTweetsToCurrentCampaign"
                />
                <PrivateRoute
                  component={CreateTwitterCampaign}
                  exact
                  path="/CreateTwitterCampaign"
                />
                <PrivateRoute
                  component={CreateEmailCampaign}
                  exact
                  path="/CreateEmailCampaign"
                />
                <PrivateRoute
                  component={CreateIncident}
                  exact
                  path="/CreateIncident"
                />
                <PrivateRoute
                  component={CreateVideoIncident}
                  exact
                  path="/CreateVideoIncident"
                />
                <PrivateRoute
                  component={CreateArticleIncident}
                  exact
                  path="/CreateArticleIncident"
                />
                <PrivateRoute
                  component={CreateImageIncident}
                  exact
                  path="/CreateImageIncident"
                />
                <PrivateRoute component={Admin} path="/dashboard" exact />
              </Switch>
            </CurrentCampaignIdContext.Provider>
          </AlertContext.Provider>
        </AdminContext.Provider>
      </Router>
    </div>
  );
}
