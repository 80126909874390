import { useEffect, useState } from "react";
import { X } from 'react-feather';
import { editImageOfCurrentIncident } from "../../helperFunctions/APICallerFunctions";
import { useToast } from "@chakra-ui/react";

function EditImg({ setShowEditImgCompo, setCurrImg, currImg }) {
	const incident_id = JSON.parse(localStorage.getItem("currentIncidentId"));
	const [img, setImg] = useState({ incident_id: incident_id, });

	const maxLengthOfDescription = 160
	const [errors, setErrors] = useState({});

	const handleSubmit = () => {
		if (!img.description || img.description === "") errors.description = "Campaign description is Required";
		else if (img.description.length > maxLengthOfDescription)
			errors.description = `Please try making it less than ${maxLengthOfDescription} characters.`;
		else errors.description = "";
		setErrors({ ...errors });
		if (img.description.length > 0 && img.description.length < maxLengthOfDescription) {
			editImageOfCurrentIncident(img, setShowEditImgCompo, setCurrImg, toast)
		}
	}

	const base64String = btoa(String.fromCharCode(...new Uint8Array(img?.img?.data?.data)));

	useEffect(() => {
		setImg({ ...img, ...currImg })
	}, [])

	const toast = useToast()

	const handleChagne = (e) => {
		const { name, value } = e.target
		setImg({
			...img, [name]: value
		})
	}

	return (
		<>
			<div className="overlay"></div>
			<div className=" bg-white fixed lg:max-w-md lg:w-full m-auto sm:left-[35%] top-1  z-50 p-4 rounded-md">
				<div>
					<X
						onClick={() => setShowEditImgCompo(false)}
						className=" border cursor-pointer hover:bg-gray-100 w-7 h-7 rounded-full p-1"
					/>
					<div className=" p-4 flex justify-center space-x-8">
						<div
							className=" cursor-pointer 
              font-semibold  border-b-2 border-primary
          ">
							Edit Image
						</div>
					</div>
					<div className="flex flex-col">
						<div
							className={`border-2 my-4 bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 $`}
						>
							<img className=" w-full " src={`data:image/png;base64,${base64String}`} alt={img.description} />
							<textarea
								cols="25"
								name="description"
								rows="7"
								value={img.description}
								onChange={handleChagne}
								className={`w-full px-2 pb-1.5 bg-gray-100 outline-none focus:border-blue-500 font-light rounded-md `}
							></textarea>
							<button
								type="submit"
								onClick={() => handleSubmit()}
								className="bg-green-400 hover:bg-green-300 cursor-pointer  text-gray-700  py-2 px-6 rounded font-bold max-w-[6rem] w-full"
							>
								Save
							</button>
						</div>
						{errors.description && (
							<p className=" text-red-400  text-xs font-medium	 ">
								{errors.description}
							</p>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

export default EditImg;
