import React, { useContext, useEffect, useState } from "react";
import ImgArchives from "../components/Archives/ImgArchives";
import VideoArchive from "../components/Archives/VideoArchive";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Badge from "../components/User/Badge";
import { deleteIncidentMetadata, editIncidentMetadata, fetchCurrentIncident, fetchIncidentsImgs, fetchIncidentsVideos, fetchOldIncidents, fetchRecentIncidents } from "../helperFunctions/APICallerFunctions";
import { MapPin, Calendar, Edit2, Twitter, Edit, X } from "react-feather";
import ArticleArchive from "../components/Archives/ArticleArchive";
import { useHistory } from "react-router-dom";
import IncidentCard from "../components/Archives/IncidentCard";
import { useToast } from "@chakra-ui/react";
import { AdminContext } from "../AdminContext";
import Loader from "../components/Loader/Loader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Input from "../components/Input";

function Archives() {
	const [tabIndex, setTabIndex] = useState(0);
	const toast = useToast()
	let history = useHistory();
	const { isAdminAuth } = useContext(AdminContext)
	const [imgArchives, setImgArchives] = useState({})
	const [videoArchives, setVideoArchives] = useState({})
	const [currentIncident, setCurrentIncident] = useState({})
	const [recentIncidents, setRecentIncidents] = useState([])
	const [oldIncidents, setOldIncidents] = useState([]);
	const [editIncident, setEditIncident] = useState(true);
	const [isLoadingVideos, setIsLoadingVideos] = useState(false);
	const [isLoadingImgs, setIsLoadingImgs] = useState(false);
	const [editCurrIncident, setEditCurrIncident] = useState(null);

	useEffect(() => {
		fetchCurrentIncident(setCurrentIncident, toast)
		fetchRecentIncidents(setRecentIncidents, toast)
	}, [])

	useEffect(() => {
		localStorage.setItem(
			"currentIncidentId",
			JSON.stringify(currentIncident?._id)
		);
	}, [currentIncident])


	function EditIncident({ currentIncident }) {
		const toast = useToast()
		const [incident, setIncident] = useState(currentIncident);

		const formSchema = Yup.object().shape({
			title: Yup.string()
				.required("Title is mandatory")
				.max(60, "Title Cannot be more than 60 Characters"),
			description: Yup.string()
				.required("description is mandatory")
				.max(160, "description Cannot be more than 160 Characters"),
			location: Yup.string()
				.required("location is mandatory"),
			date_of_incident: Yup.string()
				.required("date_of_incident is mandatory"),
			number_of_displaced: Yup.number()
				.required("number_of_displaced is mandatory"),
			number_of_injured: Yup.number()
				.required("number_of_injured is mandatory"), number_of_killed: Yup.number()
					.required("number_of_killed is mandatory"),
		});

		const formOptions = { resolver: yupResolver(formSchema) };
		const {
			register,
			handleSubmit,
			formState: { errors },
		} = useForm(formOptions);


		return (
			<div >
				<div className="overlay"></div>
				<div className="flex items-center justify-center  m-auto  ">
					<div className="bg-white p-4 rounded  max-w-md  w-full  fixed top-10   z-50 ">
						<div>
							<div className="flex flex-col  ">
								<div className="my-2">
									<X
										onClick={() => setEditCurrIncident(false)}
										className=" border cursor-pointer hover:bg-gray-100 w-7 h-7 rounded-full p-1"
									/>
									<div className=' space-y-4 py-2 ' >
										<Input
											label="Title"
											type="text"
											name="title"
											register={register}
											errors={errors}
											defaultValue={incident.title}
										/>
										<Input
											label="Description"
											type="text"
											name="description"
											register={register}
											errors={errors}
											defaultValue={incident?.description}

										/>
										<Input
											label="Location"
											type="text"
											name="location"
											register={register}
											errors={errors}
											defaultValue={incident?.location}

										/><Input
											label="Date_of_incident"
											type="date"
											name="date_of_incident"
											register={register}
											errors={errors}
											defaultValue={incident?.date_of_incident}

										/>
										<div className="grid grid-cols-3 gap-2">
											<Input
												label="Displaced"
												type="number"
												name="number_of_displaced"
												register={register}
												errors={errors}
												defaultValue={incident?.number_of_displaced}

											/><Input
												label="Injured"
												type="number"
												name="number_of_injured"
												register={register}
												errors={errors}
												defaultValue={incident?.number_of_injured}

											/><Input
												label="Killed"
												type="number"
												name="number_of_killed"
												register={register}
												errors={errors}
												defaultValue={incident?.number_of_killed}

											/>
										</div>
									</div>
								</div>
							</div>
							<div className="flex justify-between items-center" >

								<button
									onClick={() => {
										deleteIncidentMetadata(incident._id, fetchCurrentIncident, setCurrentIncident, setEditCurrIncident, toast)
									}
									}
									className=" text-red-400 capitalize  border-2 border-red-100 my-4  py-2 px-4  rounded font-semibold justify-self-end "
								>
									Delete
								</button>
								<button
									onClick={handleSubmit(async data => {
										await editIncidentMetadata({ ...data, _id: incident._id }, setEditCurrIncident, setCurrentIncident, toast)
									})}
									className=" text-dark capitalize  border-2 border-dark my-4  py-2 px-4  rounded font-semibold justify-self-end "
								>
									Edit Incident
								</button>
							</div>
						</div >
					</div>
				</div>
			</div >
		);
	}

	return <div className="my-4 px-2 lg:px-4 max-w-2xl lg:max-w-5xl  m-auto">
		<div className="userProfileDivider"></div>
		{
			isAdminAuth && <div className=" flex justify-end items-center my-4 ">
				<div
					onClick={() => setEditIncident(!editIncident)}
					className={`   mr-3 bg-gray-200 w-1/6 max-w-[4rem]  h-full cursor-pointer rounded-full  transition-all duration-1000 ease-in-out  shadow-inner ${editIncident && " bg-green-400 "
						}`}
				>
					<div
						className={` toggleCircle transition-all duration-200 ease-out  shadow-main rounded-full bg-gray-900 flex justify-center items-center text-center noselect  ${editIncident
							? "text-green-400 relative -right-1.5 translate-x-full "
							: "  text-gray-400  "
							}`}
					>
						<Edit2 className=" scale-75" />
					</div>
				</div>
			</div>
		}
		<div >
			{currentIncident ? (
				<>
					<div className="xl:grid grid-cols-2   max-w-5xl gap-6 m-auto  ">
						<div>
							<div className="flex gap-6 items-center" >
								<Badge
									title={
										currentIncident.title
									}
								/>
								{isAdminAuth && <div onClick={() => setEditCurrIncident(true)} className=" cursor-pointer hover:bg-gray-100 border p-1.5 rounded text-gray-500  " >
									<Edit />
								</div>}
							</div>
							<div className="flex items-center text-tiny gap-4 mb-2 font-medium text-dark">
								<p className="flex items-center  gap-1"> <Calendar className=" w-5 " /> {currentIncident.date_of_incident}</p>

								<p className="flex items-center  gap-1"> <MapPin className=" w-5 " /> {currentIncident.location}</p>
							</div>
							<p className=" text-grey " >{currentIncident.description}</p>
						</div>
						<div className="flex xl:flex-col gap-4 xl:items-end">
							<p>
								Killed : <span className="font-semibold" >{currentIncident.number_of_killed}</span>
							</p>
							<p>
								Displaced : <span className="font-semibold" >{currentIncident.number_of_displaced}</span>
							</p>
							<p>
								Injured : <span className="font-semibold" >{currentIncident.number_of_injured}</span>
							</p>

						</div>
					</div>
				</>
			) : null}
		</div>
		<div className="userProfileDivider"></div>


		<Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
			<TabList className=" my-4 justify-center flex gap-8 ">
				<Tab className={` cursor-pointer ${tabIndex === 0
					&& `font-semibold  border-b-2 border-primary`}`} >Articles</Tab>
				<Tab onClick={() => {
					if (Object.keys(currentIncident).length > 0) fetchIncidentsVideos(currentIncident._id, setVideoArchives, toast, setIsLoadingVideos)

				}} className={` cursor-pointer ${tabIndex === 1
					&& `font-semibold  border-b-2 border-primary`}`}>Videos</Tab>
				<Tab onClick={() => {
					if (Object.keys(currentIncident).length !== 0) fetchIncidentsImgs(currentIncident._id, setImgArchives, toast, setIsLoadingImgs)

				}} className={` cursor-pointer ${tabIndex === 2
					&& `font-semibold  border-b-2 border-primary`}`} >Images</Tab>

			</TabList>
			<div className="userProfileDivider "></div>
			<>
				<TabPanel >
					{isAdminAuth && <div className="flex my-2 justify-end  max-w-5xl m-auto " >
						<button className="btn my-2" onClick={() => history.push("/CreateArticleIncident")} >Add Article</button>
					</div>}
					<div className=" grid md:grid-cols-2 xl:grid-cols-3 max-w-5xl m-auto gap-4  " >
						{currentIncident?.articles?.length > 0 ?
							<ArticleArchive articles={currentIncident.articles} editIncident={editIncident} incident_id={currentIncident._id} />
							: <>
								<span></span>
								<p className='text-center text-gray-600' >Does not include Articles Currently</p>
								<span></span>
							</>}
					</div>
				</TabPanel>
				<TabPanel >
					{isAdminAuth && <div className="flex justify-end my-2  max-w-5xl m-auto " >
						<button className="btn" onClick={() => history.push("/CreateVideoIncident")} >Add Videos</button>
					</div>}
					{
						isLoadingVideos ?
							<Loader />
							:
							<VideoArchive
								videoArchives={videoArchives}
								editIncident={editIncident}
							/>

					}
				</TabPanel>
				<TabPanel>
					{isAdminAuth &&
						<div className="flex my-2 justify-end  max-w-5xl m-auto " >
							<button className="btn" onClick={() => history.push("/CreateImageIncident")} >Add Image</button>
						</div>}
					{
						isLoadingImgs ?
							<Loader />
							:
							<ImgArchives imgArchives={imgArchives} editIncident={editIncident} incident_id={currentIncident?._id} />
					}
				</TabPanel>
			</>
		</Tabs>
		<div className="userProfileDivider"></div>

		{
			recentIncidents.length > 0 && <div className="grid  md:grid-cols-2 xl:grid-cols-3 max-w-5xl gap-6 m-auto mt-4   ">
				<Badge text="Recent Incidents" />
			</div>
		}
		<div className="grid md:grid-cols-2 xl:grid-cols-3 max-w-5xl m-auto gap-4  "  >
			{recentIncidents.length > 0 && <IncidentCard editIncident={editIncident} fetchedIncidents={recentIncidents} setFetchedIncidents={setCurrentIncident} currentIncidentId={currentIncident._id} />}

			{
				recentIncidents.length === 5 && oldIncidents.length < 1 && <button onClick={() => fetchOldIncidents(setOldIncidents, toast)} className='btn h-max  block'>Load More Incidents</button>
			}
			{
				oldIncidents.length > 0 && <IncidentCard editIncident={editIncident} fetchedIncidents={oldIncidents} setFetchedIncidents={setCurrentIncident} currentIncidentId={currentIncident._id} />}
		</div>
		{Object.keys(currentIncident).length > 0 && editCurrIncident && <EditIncident currentIncident={currentIncident} />}
	</div >;
}

export default React.memo(Archives);
