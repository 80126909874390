import { useToast } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { CurrentCampaignIdContext } from "../components/Context/CurrentCampaignId";
import Badge from "../components/User/Badge";
import {
  fetchCurrentTwitterCampaigns,
  fetchOldTwitterCampaigns,
  fetchRecentTwitterCampaigns,
  fetchTwitterCampaignDetails,
} from "../helperFunctions/APICallerFunctions";
import { AdminContext } from "../AdminContext";
import { useHistory } from 'react-router-dom';


function TweetsCampaign() {
  const toast = useToast();
  const [currentTwitterCampaign, setCurrentTwitterCampaign] = useState({});
  const [renderedCurrentTwitterCampaign, setRenderedCurrentTwitterCampaign] =
    useState([]);
  const [renderedRecentTwitterCampaigns, setRenderedRecentTwitterCampaigns] =
    useState([]);
  const [oldTwitterCampaigns, setOldTwitterCampaigns] = useState([]);
  const { setCurrentCampaignId } = useContext(CurrentCampaignIdContext);
  const history = useHistory();

  useEffect(() => {
    fetchCurrentTwitterCampaigns(setCurrentTwitterCampaign, toast);
    fetchRecentTwitterCampaigns(setRenderedRecentTwitterCampaigns, toast);
  }, []);

  const generateLink = (tweet) =>
    "https://twitter.com/intent/tweet?text=" + encodeURIComponent(tweet);

  useEffect(() => {
    setRenderedCurrentTwitterCampaign(currentTwitterCampaign);
    setCurrentCampaignId(currentTwitterCampaign && currentTwitterCampaign?._id);
  }, [currentTwitterCampaign, setCurrentCampaignId]);

  const { isAdminAuth } = useContext(AdminContext);
  const [emailUserName, setEmailUserName] = useState("");
  const [show, setShow] = useState();

  return (
    <>
      <div className="my-4 px-4">
        {show && !isAdminAuth ? (
          <div>
            <div className="overlay"></div>
            <div className=" flex justify-center items-center bg-gray-400  ">
              <div className=" fixed top-20 sm:top-36  z-50  bg-white px-8 py-4 pb-8 md:rounded-md  w-11/12 sm:w-full max-w-sm   border border-blue-500">
                <div
                  onSubmit={(e) => {
                    e.preventDefault();
                    setShow(false);
                  }}
                  className="space-y-6"
                >
                  <div className=" w-full flex flex-col gap-4 justify-center items-center "></div>
                  <div>
                    <label
                      className=" text-xs block mb-1  text-text font-semibold"
                      htmlFor="name"
                    >
                      Enter name
                    </label>
                    <input
                      className=" outline-none border-2 border-transparent focus:border-blue-500 bg-gray-100 rounded-md p-3 w-full "
                      type="text"
                      id="name"
                      name="name"
                      value={emailUserName}
                      onChange={(e) => setEmailUserName(e.target.value)}
                    />
                  </div>
                  <button
                    onClick={(e) => setShow(false)}
                    type="button"
                    className="transform active:scale-90 duration-75 block text-center btn w-full my-6"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="max-w-2xl lg:max-w-5xl  m-auto">
          <div>
            {renderedCurrentTwitterCampaign ? (
              <>
                <div className="md:grid  grid-cols-2 xl:grid-cols-3  gap-6  mt-4   ">
                  <div>
                    <p className=" font-bold text-2xl">
                      {renderedCurrentTwitterCampaign.title}
                    </p>
                    <p className=" text-grey text-base ">
                      {renderedCurrentTwitterCampaign.description}
                    </p>
                  </div>
                </div>
                <div className="sm:grid justify-between grid-cols-2 lg:grid-cols-3    ">
                  {renderedCurrentTwitterCampaign?.tweets?.map(
                    (tweet, index) => {
                      return (
                        <a
                          key={index}
                          className=" my-2 m-auto cursor-pointer  h-auto   w-full sm:max-w-xs  border hover:bg-blue-50 flex  flex-col justify-between  space-y-4 p-6 rounded-xl"
                          href={generateLink(tweet["body"])}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <p className=" font-medium text-text  break-words">
                            {tweet["body"]}
                          </p>
                          <div className="flex justify-between items-center">
                            <p className="text-2xl">{index + 1}</p>
                            <a
                              href={generateLink(tweet["body"])}
                              target="_blank"
                              rel="noreferrer"
                              className="btn"
                            >
                              Tweet
                            </a>
                          </div>
                        </a>
                      );
                    }
                  )}
                </div>
                <div className="userProfileDivider"></div>
              </>
            ) : null}
          </div>
          {renderedCurrentTwitterCampaign && (
            <div className="md:grid  grid-cols-2 xl:grid-cols-3 gap-6  mt-4   ">
              {renderedRecentTwitterCampaigns && (
                <Badge text="Recent Campaigns" />
              )}
            </div>
          )}
          <div className="grid justify-between sm:grid-cols-2 sm:gap-4 lg:grid-cols-3  mx-auto w-fit   ">
            {currentTwitterCampaign && (
              <div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.99 }}
                className=" border shadow-main   cursor-pointer my-4 p-6 space-y-4 rounded-xl  w-full max-w-xs  "
                onClick={() => {
                  setRenderedCurrentTwitterCampaign(currentTwitterCampaign);
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <div className=" m-auto w-full    cursor-pointer ">
                  <div>
                    <div className="flex items-center justify-between">
                      <p className=" font-bold text-dark ">
                        {currentTwitterCampaign.title}
                      </p>
                      <p className=" font-bold text-xs text-dark bg-green-300 px-2 py-1  rounded-md ">
                        Current
                      </p>
                    </div>

                    <p className="text-tiny font-semibold text-dark my-4 ">
                      {currentTwitterCampaign.description}
                    </p>
                  </div>
                </div>
              </div>
            )}
            {renderedRecentTwitterCampaigns?.map((camp, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    fetchTwitterCampaignDetails(
                      camp._id,
                      setRenderedCurrentTwitterCampaign,
                      toast,
                      history
                    );
                  }}
                  className=" hover:cursor-pointer border shadow-main  my-4 p-6 space-y-4 rounded-xl flex flex-col  w-full max-w-xs  "
                >
                  <p className=" font-bold text-dark "> {camp.title} </p>
                  <p className="text-tiny font-semibold text-text  my-4 ">
                    {camp.description}
                  </p>
                  <p className="text-2xl">{index + 2}</p>
                </div>
              );
            })}
            {oldTwitterCampaigns.length < 1 && (
              <button
                onClick={() =>
                  fetchOldTwitterCampaigns(setOldTwitterCampaigns, toast)
                }
                className="btn block"
              >
                Load More Twitter Campaigns
              </button>
            )}
            {oldTwitterCampaigns.length > 0 &&
              oldTwitterCampaigns?.map((camp, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      fetchTwitterCampaignDetails(
                        camp._id,
                        setRenderedCurrentTwitterCampaign,
                        toast,
                        history
                      );
                    }}
                    className=" hover:cursor-pointer border shadow-main  my-4 p-6 space-y-4 rounded-xl flex flex-col  w-full max-w-xs"
                  >
                    <p className=" font-bold text-dark "> {camp.title} </p>
                    <p className="text-tiny font-semibold text-text  my-4 ">
                      {camp.description}
                    </p>
                    <p className="text-2xl">{index + 7}</p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}
export default TweetsCampaign;
