import { useState } from "react";
import { useHistory } from "react-router-dom";
import { addArticleToCurrentIncident } from "../../helperFunctions/APICallerFunctions";
import { useToast } from "@chakra-ui/react";

function CreateArticleIncident() {
	const incident_id = JSON.parse(localStorage.getItem("currentIncidentId"));
	const [errors, setErrors] = useState({});
	const maxLengthOfDescription = 160
	const [article, setArticle] = useState({
		incident_id,
		article_link: "",
		description: "",
	});
	const toast = useToast()
	let history = useHistory();

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setArticle({
			...article,
			[name]: value,
		});
	};

	const handleSubmit = () => {
		if (!article.article_link || article.article_link === "") errors.article_link = "Please add article link";
		if (!article.description || article.description === "") errors.description = "Campaign description is Required";
		else if (article.description.length > maxLengthOfDescription)
			errors.description = `Please try making it less than ${maxLengthOfDescription} characters.`;
		else errors.description = "";
		if (article.article_link.length > 0) errors.article_link = "";

		setErrors({ ...errors });

		if (article.article_link !== "" && article.description !== "" && article.description.length < maxLengthOfDescription) {
			addArticleToCurrentIncident(article, toast, history)
		}
	}

	return (
		<>
			<div className="w-10/12 max-w-md m-auto ">
				<div>
					<div className=" p-4 flex justify-center space-x-8">
						<div
							className=" cursor-pointer 
              font-semibold  border-b-2 border-primary
          ">
							Add Article to Current Incident
						</div>
					</div>
					<div className="flex my-2 gap-2 flex-col">
						<div
							className={`border-2 bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 ${errors.article_link && "border-red-400 focus:border-red-500"
								}`}
						>
							<label
								className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
								htmlFor="article_link"
							>
								Article Link?
							</label>
							<input
								name="article_link"
								value={article.article_link}
								onChange={handleInputChange}
								className="w-full px-2 pb-1.5 bg-gray-100 outline-none  font-light rounded-md"
								type="text"
								id="link"
								required
							/>
						</div>
						{errors.article_link && (
							<p className=" text-red-400  text-xs font-medium	 ">
								{errors.article_link}
							</p>
						)}
						<div className={`border-2 bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 ${errors.description && "border-red-400 focus:border-red-500"
							}`}>
							<div className="flex justify-between" >
								<label
									className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
									htmlFor="description"
								>
									description
								</label>
								<span
									className={`text-xs select-none  font-semibold placeholder-gray-gray4 px-2 pt-1.5 ${article.description.length > maxLengthOfDescription
										? "text-red-500"
										: "text-text"
										}`}
								>
									{`${article.description.length}/ ${maxLengthOfDescription} `}
								</span>
							</div>
							<textarea
								name="description"
								value={article.description}
								onChange={handleInputChange}
								cols="25"
								rows="7"
								className={`w-full px-2 pb-1.5 bg-gray-100 outline-none focus:border-blue-500 font-light rounded-md `}
							></textarea>
						</div>
						{errors.description && (
							<p className=" text-red-400  text-xs font-medium	 ">
								{errors.description}
							</p>
						)}
					</div>
				</div>
				<button
					type="button"
					onClick={() => handleSubmit()}
					className={`btn w-full text-center`}
				>
					Share
				</button>
			</div>
		</>
	);
}

export default CreateArticleIncident;
