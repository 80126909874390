import useLockBodyScroll from "../../hooks/useLockBodyScroll";

function ConfirmDeleteDialog({ confirmDelete, idAndIndex, setIsShowingConfirmDeleteComponent }) {
  useLockBodyScroll();

  return (
    <>
      <div
        onClick={() => setIsShowingConfirmDeleteComponent(false)}
        className="overlay"
      ></div>
      <div className="z-50 fixed  py-6 px-8  bg-white left-10 sm:left-[40%] top-1/3 sm:top-[44%] rounded-xl   inline-block text-center   ">
        <div className=" mt-2 mb-6 ">
          do you really want to delete?
          <br />
          <span className="font-bold text-red-500 ">
            it canot be undo later!!
          </span>
        </div>
        <div className=" flex justify-between gap-2  ">
          <button
            className=" bg-gray-50 text-gray-700 hover:bg-gray-100 py-2 px-6 rounded font-bold"
            onClick={() => setIsShowingConfirmDeleteComponent(false)}
          >
            cancel
          </button>
          <br />
          <button
            className=" bg-red-400 hover:bg-red-300 text-red-900  py-2 px-6 rounded font-bold"
            onClick={() => {
              if (idAndIndex.tweetId) {
                confirmDelete(idAndIndex.tweetId, idAndIndex.index);
              } else if (idAndIndex.emailId) {
                confirmDelete(idAndIndex.emailId, idAndIndex.index);
              }
              setIsShowingConfirmDeleteComponent(false);
            }}
          >
            confirm
          </button>
        </div>
      </div>
    </>
  );
}

export default ConfirmDeleteDialog;
