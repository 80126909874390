export const signInWithAPI = async (
  setSignInloader,
  data,
  setIsAdminAuth,
  toast,
  history
) => {
  await fetch("/api/v1/sign_in", {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        setIsAdminAuth(true)
        toast({
          title: data.message,
          status: 'success', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
        history.push("dashboard")
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
        setSignInloader(false);
      } else {
        toast({
          title: "Something is wrong please retry!",
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
        setSignInloader(false);
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
      setSignInloader(false);
    });
};

export const signUpWithAPI = async (
  data,
  toast,
  setSignUpLoader,
  history
) => {
  await fetch("/api/v1/sign_up", {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      setSignUpLoader(true);
      let data = await res.json();
      if (res.status === 200) {
        setSignUpLoader(false);
        history.push(`/SignIn`);
        toast({
          title: data.message,
          status: 'success', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
        setSignUpLoader(false);
      } else {
        toast({
          title: "Something is wrong please retry!",
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
        setSignUpLoader(false);
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
      setSignUpLoader(false);
    });
};

export const createCampaign = async (
  campaign,
  tweetsList,
  toast,
  history,
  setUploadingTweetsLoader
) => {
  const tweets = tweetsList.map(tweet => tweet.body)
  await fetch(`/api/v1/create_twitter_campaign`, {
    method: "POST",
    body: JSON.stringify({
      tweets,
      title: campaign["title"],
      description: campaign["description"],
    },
    ),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        toast({
          title: data.message,
          status: 'success', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
        history.goBack();
      } else if (res.status === 201) {
        setUploadingTweetsLoader(false);
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        setUploadingTweetsLoader(false);
        toast({
          title: "Something is wrong please retry!",
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      }
    })
    .catch((err) => {
      setUploadingTweetsLoader(false);
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
    });
};

export const createEmailCampaign = async (
  emailCampaign,
  history, toast
) => {
  await fetch(`/api/v1/create_email_campaign`, {
    method: "POST",
    body: JSON.stringify(emailCampaign),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        history.goBack();
        toast({
          title: data.message,
          status: 'success', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast({
          title: "Something is wrong please retry!",
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      });
    });
};

export const updateTwitterCampaignMetadataAPI = async (
  _id, { title, description },
  toast, renderedRecentCampaign,
  setRenderedRecentCampaign, index, setIsEditing
) => {
  await fetch("/api/v1/edit_twitter_campaign_metadata", {
    method: "PATCH",
    body: JSON.stringify({ twitter_campaign_id: _id, title, description }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        renderedRecentCampaign[index]["title"] = title;
        renderedRecentCampaign[index]["description"] = description;
        setRenderedRecentCampaign(renderedRecentCampaign);
        setIsEditing(false);
        toast({
          title: data.message,
          status: 'success', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast({
          title: "Something is wrong please retry!",
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
    });
};

export const updateEmailCampaignMetadataAPI = async (
  emailMetaData,
  setIsEditing,
  setRenderedCurrentEmailCampaign,
  toast,
) => {

  await fetch("/api/v1/edit_email_campaign_metadata", {
    method: "PATCH",
    body: JSON.stringify(emailMetaData),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        setIsEditing(false)
        setRenderedCurrentEmailCampaign(prevState => ({
          ...prevState,
          title: emailMetaData.title,
          description: emailMetaData.description,
        }));

        // renderedRecentEmailCampaigns[index]["title"] = emailMetaData.title;
        // renderedRecentEmailCampaigns[index]["description"] = emailMetaData.description;
        // setRenderedRecentEmailCampaigns([...renderedRecentEmailCampaigns]);
        // setIsEditingMetaData(false);
        toast({
          title: data.message,
          status: 'success', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast({
          title: "Something is wrong please retry!",
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
    });
};

export const deleteTwitterCampaign = async (
  campaignId,
  index,
  toast,
  renderedRecentCampaign,
  setRenderedRecentCampaign
) => {

  await fetch(`/api/v1/delete_twitter_campaign/${campaignId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        let updatedRecentCampaignsList = renderedRecentCampaign.filter(
          (_, idx) => idx !== index
        );
        setRenderedRecentCampaign(updatedRecentCampaignsList);
        toast({
          title: data.message,
          status: 'success', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast({
          title: "Something is wrong please retry!",
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
    });
};

export const deleteEmailCampaign = async (
  emailCampaignId, setRenderedCurrentEmailCampaign, fetchCurrentEmailCampaign, toast
) => {

  await fetch(`/api/v1/delete_email_campaign/${emailCampaignId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        setRenderedCurrentEmailCampaign({})
        fetchCurrentEmailCampaign(setRenderedCurrentEmailCampaign, toast)
        toast({
          title: data.message,
          status: 'success', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast({
          title: "Something is wrong please retry!",
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
    });
};

export const fetchCurrentTwitterCampaigns = (setCurrentTwitterCampaign, toast) => {
  fetch("/api/v1/get_current_twitter_campaign")
    .then(async (res) => {
      if (res.status === 200) {
        let data = await res.json();
        setCurrentTwitterCampaign(data);
      } else if (res.status === 201) {
        let data = await res.json();
        toast(true, data["message"], "danger");
      } else {
        toast(true, " Something is wrong please retry! ", "danger");
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
    });
};

export const fetchRecentTwitterCampaigns = (setRenderedRecentTwitterCampaigns, toast) => {
  fetch("/api/v1/get_metadata_for_five_previous_twitter_campaigns")
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        setRenderedRecentTwitterCampaigns(data);
      } else if (res.status === 201) {
        toast(true, data["message"], "danger");
      } else {
        toast(true, " Something is wrong please retry! ", "danger");
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
    });
};

export const fetchOldTwitterCampaigns = (setOldTwitterCampaigns,) => {
  fetch("/api/v1/get_metadata_for_old_previous_twitter_campaigns")
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        setOldTwitterCampaigns(data);
      }
    })

};

export const fetchCurrentEmailCampaign = (setCurrentEmailCampaign, toast) => {
  fetch("/api/v1/get_current_email_campaign", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        setCurrentEmailCampaign(data);
      } else if (res.status === 201) {
        toast(true, data["message"], "danger");
      } else {
        toast(true, " Something is wrong please retry! ", "danger");
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
    });
};

export const fetchRecentEmailCampaigns = (setRenderedRecentEmailCampaigns, toast) => {
  fetch("/api/v1/get_metadata_for_five_previous_email_campaigns", {
    method: "GET", headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        setRenderedRecentEmailCampaigns(data);
      } else if (res.status === 201) {
        toast(true, data["message"], "danger");
      } else {
        toast(true, " Something is wrong please retry! ", "danger");
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
    });
};

export const fetchOldEmailCampaigns = (setOldCampaigns, toast) => {
  fetch("/api/v1/get_metadata_for_old_previous_email_campaigns", {
    method: "GET", headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        setOldCampaigns(data);
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else toast(true, " Something is wrong please retry! ", "danger");
    })
    .catch((err) => toast({
      title: err.message,
      status: 'error', position: "bottom-right",
      duration: 4000,
      isClosable: true,
    }));
};

export const fetchEmailCampaignDetails = async (
  campaign_id,
  setRenderedCurrentEmailCampaign,
  toast
) => {
  await fetch(`/api/v1/get_email_campaign_details/${campaign_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        setRenderedCurrentEmailCampaign(data);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast({
          title: "something is wrong please try again",
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
    });
};

export const fetchTwitterCampaignDetails = async (
  campaign_id,
  setRenderedCurrentCampaign,
  toast,
  history
) => {
  await fetch(`/api/v1/get_twitter_campaign_details/${campaign_id}`)
    .then(async (res) => {
      if (res.status === 200) {
        let data = await res.json();
        setRenderedCurrentCampaign(data);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        history.push(`/twitter/${campaign_id}`);
      } else if (res.status === 201) {
      } else {
      }
    })
    .catch((err) => {
    });
};

export const deleteTweet = async (
  tweet_id,
  twitterCampaignId,
  tweets,
  index,
  setTweets,
  toast
) => {
  await fetch(`/api/v1/delete_tweet`, {
    method: "DELETE",
    body: JSON.stringify({
      tweet_id,
      twitter_campaign_id: twitterCampaignId,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        let updatedTweets = tweets.filter((_, idx) => idx !== index);
        setTweets(updatedTweets);
        toast({
          title: data.message,
          status: 'success', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast({
          title: data.message,
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    });
};

export const editTweet = async (
  tweet_id,
  newEditedTweetBody,
  tweets,
  editTweetIndex,
  setTweets,
  setIsEditing,
  toast
) => {
  await fetch("/api/v1/edit_tweet", {
    method: "PATCH",
    body: JSON.stringify({
      body: newEditedTweetBody,
      tweet_id
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        tweets[editTweetIndex]["body"] = newEditedTweetBody;
        setTweets(tweets);
        setIsEditing(false);
        toast({
          title: data.message,
          status: 'success', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        });
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast(true, "something is wrong please try later", "danger");
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
    });
};

export const addTweetsToCurrentCamp = async (
  id,
  tweetsList,
  toast,
  history,
  setUploadingTweetsLoader
) => {
  const tweets = tweetsList.map(tweet => tweet.body)
  await fetch("/api/v1/add_tweets_to_twitter_campaign", {
    method: "POST",
    body: JSON.stringify({
      tweets,
      twitter_campaign_id: id,
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        toast({
          title: data.message,
          status: 'success', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
        history.goBack();
      } else if (res.status === 201) {
        setUploadingTweetsLoader(false);
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
        setUploadingTweetsLoader(false);
      } else {
        setUploadingTweetsLoader(false);
        toast(true, " Something is wrong please retry! ", "danger");
      }
    })
    .catch((err) => {
      setUploadingTweetsLoader(false);
      toast(true, err, "danger");
    });
};

export const fetchCurrentIncident = (setCurrentIncident, toast) => {
  fetch("/api/v1/get_current_incident")
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        setCurrentIncident(data);
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast(true, " Something is wrong please retry! ", "danger");
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
    });
};

export const fetchRecentIncidents = (setRecentIncidents, toast) => {
  fetch("/api/v1/get_metadata_for_five_previous_incidents")
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        setRecentIncidents(data);
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast(true, " Something is wrong please retry! ", "danger");
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
    });
};

export const fetchIncidentsImgs = (incident_id, setRecentIncidents, toast, setIsLoadingImgs) => {
  setIsLoadingImgs(true)
  fetch(`/api/v1/get_images_for_incident/${incident_id}`)
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        setRecentIncidents(data);
        setIsLoadingImgs(false)
      } else if (res.status === 201) {
        setIsLoadingImgs(false)
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        setIsLoadingImgs(false)
        toast({
          title: " Something is wrong please retry! ",
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      }
    })
    .catch((err) => {
      setIsLoadingImgs(false)
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
    });
};

export const fetchIncidentsVideos = (incident_id, setRecentIncidents, toast, setIsLoadingVideos) => {
  setIsLoadingVideos(true)
  fetch(`/api/v1/get_videos_for_incident/${incident_id}`)
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        setIsLoadingVideos(false)
        setRecentIncidents(data);
      } else if (res.status === 201) {
        setIsLoadingVideos(false)
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        setIsLoadingVideos(false)
        toast({
          title: " Something is wrong please retry! ",
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      }
    })
    .catch((err) => {
      setIsLoadingVideos(false)
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
    }
    );
};

export const fetchOldIncidents = (setOldIncidents, toast) => {
  fetch("/api/v1/get_metadata_for_old_previous_incidents")
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        setOldIncidents(data);
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else toast(true, " Something is wrong please retry! ", "danger");
    })
    .catch((err) => toast({
      title: err.message,
      status: 'error', position: "bottom-right",
      duration: 4000,
      isClosable: true,
    }));
};

export const fetchIcidentDetails = async (
  incident_id,
  setCurrentIncident,
  toast
) => {
  await fetch(`/api/v1/get_incident_details/${incident_id}`)
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        setCurrentIncident(data);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast(true, "something is wrong please retry!", "danger");
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
    });
};

export const createIncidentApi = async (
  { number_of_displaced, number_of_injured, number_of_killed, title, description, date_of_incident, location }, toast, history
) => {
  await fetch(`/api/v1/create_incident`, {
    method: "POST",
    body: JSON.stringify({
      number_of_displaced, number_of_injured, number_of_killed, title, description, date_of_incident, location
    },
    ),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        toast({
          title: data.message,
          status: 'success', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
        history.goBack();
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast({
          title: "Something is wrong please retry!",
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
    });
};

export const addVideoToCurrentIncident = async (
  video, toast, history
) => {
  await fetch(`/api/v1/create_video_archive`, {
    method: "POST",
    body: JSON.stringify(video),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        toast({
          title: data.message,
          status: 'success', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
        history.goBack();
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast({
          title: "Something is wrong please retry!",
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      }
    })
    .catch((err) => toast({
      title: err.message,
      status: 'error', position: "bottom-right",
      duration: 4000,
      isClosable: true,
    }));
};

export const addArticleToCurrentIncident = async (
  article, toast, history
) => {
  await fetch(`/api/v1/create_article_archive`, {
    method: "POST",
    body: JSON.stringify(article),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        toast({
          title: data.message,
          status: 'success', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
        history.goBack();
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast({
          title: "Something is wrong please retry!",
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      }
    })
    .catch((err) => toast({
      title: err.message,
      status: 'error', position: "bottom-right",
      duration: 4000,
      isClosable: true,
    }));
};

export const editIncidentsArticle = async (
  setIsEditing,
  toast,
  setArticle,
  article, articles, setArticles
) => {
  await fetch("/api/v1/edit_article_archive", {
    method: "PATCH",
    body: JSON.stringify({
      "article_link": article.article_link,
      "description": article.description,
      "article_archive_id": article._id
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        setIsEditing(false)
        setArticle({ ...article })
        const idx = articles.findIndex((art, idx) => art._id === article._id)
        let currentArticles = articles
        currentArticles[idx] = article
        setArticles([...currentArticles])
        toast({
          title: data.message,
          status: 'success', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast(true, "something is wrong please try later", "danger");
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
    });
};

export const editIncidentsVideo = async (
  videoArchivesVideos, idx, currentVideo, setVideoArchivesVideos, setIsEditing, toast
) => {
  await fetch("/api/v1/edit_video_archive", {
    method: "PATCH",
    body: JSON.stringify({ ...currentVideo, video_archive_id: currentVideo._id }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        videoArchivesVideos[idx]["video_link"] = currentVideo.video_link;
        videoArchivesVideos[idx]["description"] = currentVideo.description;
        setVideoArchivesVideos(videoArchivesVideos);
        setIsEditing(false)
        toast({
          title: data.message,
          status: 'success', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast({
          title: "Something is wrong please retry!",
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      });
    });
};

export const deleteIncidentsArticle = async (
  incident_id, id, articles, setArticles, article_archive_id, toast
) => {
  await fetch(`/api/v1/delete_article/${incident_id}/${article_archive_id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        let updatedArticles = articles.filter((_, idx) => idx !== id);
        setArticles(updatedArticles);
        toast({
          title: data.message,
          status: 'success', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast({
          title: "Something is wrong please retry!",
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
    });
};

export const deleteIncidentsVideo = async (
  incident_id, video_archive_id, idx, setVideoArchivesVideos, videoArchivesVideos, toast
) => {
  await fetch(`/api/v1/delete_video_archive/${incident_id}/${video_archive_id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        let updatedVideoArchivesVideos = videoArchivesVideos.filter(
          (_, index) => index !== idx
        );
        setVideoArchivesVideos(updatedVideoArchivesVideos);
        toast({
          title: data.message,
          status: 'success', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast({
          title: "Something is wrong please retry!",
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
    });
};

export const addImageToCurrentIncident = async (
  imgArchive, { incident_id, description }, toast, history
) => {

  const data = new FormData();
  const filename = Date.now() + imgArchive.name;
  data.append("image_archive", imgArchive);
  data.append("filename", filename);
  data.append("incident_id", incident_id);
  data.append("description", description);

  await fetch("/api/v1/create_image_archive", {
    method: "POST",
    body: data,
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        toast({
          title: data.message,
          status: 'success', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
        history.goBack();
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast({
          title: "Something is wrong please retry!",
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      }
    })
    .catch((err) => toast({
      title: err.message,
      status: 'error', position: "bottom-right",
      duration: 4000,
      isClosable: true,
    }));
};

export const editImageOfCurrentIncident = async (
  img, setShowEditImgCompo, setCurrImg, toast
) => {
  const { _id, description, } = img

  await fetch("/api/v1/edit_image_archive", {
    method: "PATCH",
    body: JSON.stringify({ description, "image_archive_id": _id }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        setShowEditImgCompo(false)
        setCurrImg({ ...img, description: description })
        toast({
          title: data.message,
          status: 'success', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else if (res.status === 201) {
        toast({
          title: "Something is wrong please retry!",
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast({
          title: "Something is wrong please retry!",
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      };
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
    });
};

export const deleteImg = async (
  id, imgs, setImgs, incident_id, toast
) => {

  await fetch(`/api/v1/delete_image_archive/`, {
    method: "DELETE",
    body: JSON.stringify({
      incident_id, "image_archive_id": id
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        let updatedImgs = imgs.filter(
          (img, _) => img._id !== id
        );
        setImgs(updatedImgs);
        toast({
          title: data.message,
          status: 'success', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast({
          title: "Something is wrong please retry!",
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      });
    });
};


export const fetchIncident = async (
  incident_id,
  setFetchedIncidents,
  toast
) => {
  await fetch(`/api/v1/get_incident_with_id/${incident_id}`)
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        setFetchedIncidents({ ...data });
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast(true, "something is wrong please retry!", "danger");
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
    });
};

export const editIncidentMetadata = async (
  incident, setEditCurrIncident, setCurrentIncident, toast
) => {

  const { title, description, number_of_killed, number_of_displaced, number_of_injured, location, date_of_incident, _id } = incident


  await fetch(`/api/v1/edit_incident_metadata/${_id}`, {
    method: "PATCH",
    body: JSON.stringify({ title, description, number_of_killed, number_of_displaced, number_of_injured, location, date_of_incident }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        setCurrentIncident({ ...incident })
        setEditCurrIncident(false)
        //! dont use 
        // setIncident({ ...incident })
        // const idx = incidents.findIndex((art, _) => art._id === incident._id)
        // let currIncidents = incidents
        // currIncidents[idx] = incident
        // setIncidents([...currIncidents])
        toast({
          title: data.message,
          status: 'success', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })

      } else if (res.status === 201) {
        setEditCurrIncident(false)
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        setEditCurrIncident(false)
        toast(true, "something is wrong please try later", "danger");
      }
    })
    .catch((err) => {
      setEditCurrIncident(false)
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      })
    });
};

export const deleteIncidentMetadata = async (
  incident_id, fetchCurrentIncident, setCurrentIncident, setEditCurrIncident, toast
) => {
  await fetch(`/api/v1/delete_incident/${incident_id}`, {
    method: "DELETE",
  })
    .then(async (res) => {
      let data = await res.json();
      if (res.status === 200) {
        setCurrentIncident({})
        fetchCurrentIncident(setCurrentIncident, toast)
        setEditCurrIncident(false)
        toast({
          title: data.message,
          status: 'success', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else if (res.status === 201) {
        toast({
          title: data.message,
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast({
          title: "Something is wrong please retry!",
          status: 'error', position: "bottom-right",
          duration: 4000,
          isClosable: true,
        })
      }
    })
    .catch((err) => {
      toast({
        title: err.message,
        status: 'error', position: "bottom-right",
        duration: 4000,
        isClosable: true,
      });
    });
};