import React, { useEffect, useState } from 'react'
import EditImg from './EditImg';
import ImageArchive from './ImageArchive';
import { deleteImg } from '../../helperFunctions/APICallerFunctions';
import { useToast } from '@chakra-ui/react';

function ImgArchives({ imgArchives: fetchedImgs, editIncident, incident_id }) {
	const toast = useToast()
	const [imgs, setImgs] = useState([]);
	const [showEditImgCompo, setShowEditImgCompo] = useState(false);
	const [currImg, setCurrImg] = useState();

	useEffect(() => {
		setImgs(fetchedImgs?.images);
	}, []);

	const handleDelete = (id) => {
		deleteImg(id, imgs, setImgs, incident_id, toast)
	}

	return (
		<div className="grid md:grid-cols-2 xl:grid-cols-3 max-w-5xl m-auto gap-4">
			{imgs?.length > 0 ? imgs.map((img, idx) => <ImageArchive img={img?._id === currImg?._id ? currImg : img} key={idx} setShowEditImgCompo={setShowEditImgCompo} setCurrImg={setCurrImg} editIncident={editIncident} handleDelete={handleDelete} />
			) : <>
				<span></span>
				<p className='text-center text-gray-600' >Does not include Images Currently</p>
				<span></span>
			</>}
			{showEditImgCompo && <EditImg setShowEditImgCompo={setShowEditImgCompo} setCurrImg={setCurrImg} currImg={currImg} />}
		</div>
	)
}

export default ImgArchives