import { useState } from "react";
import { Camera } from 'react-feather';
import imageCompression from "browser-image-compression";
import { addImageToCurrentIncident } from "../../helperFunctions/APICallerFunctions";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

function CreateImageIncident() {
	const incident_id = JSON.parse(localStorage.getItem("currentIncidentId"));
	const [img, setImg] = useState({
		incident_id: incident_id,
		description: "",
	});
	const [imgArchive, setImgArchive] = useState();
	const [imgData, setImgData] = useState();
	const toast = useToast()
	let history = useHistory();
	const handleChange = (e) => {
		const { name, value } = e.target
		setImg({
			...img, [name]: value
		})
	}
	const [errors, setErrors] = useState({});
	const maxLengthOfDescription = 160

	const handleSubmit = () => {
		if (!imgArchive || imgArchive === "") errors.img = "Please select Img";
		if (!imgData || imgData === "") errors.img = "Please select Img";
		if (!img.description || img.description === "") errors.description = "Campaign description is Required";
		else if (img.description.length > maxLengthOfDescription)
			errors.description = `Please try making it less than ${maxLengthOfDescription} characters.`;
		else errors.description = "";
		if (imgArchive?.length > 1) errors.img = "";
		if (imgData?.length > 1) errors.img = "";
		setErrors({ ...errors });

		if (Object.keys(imgArchive).length > 0 && img.description.length > 0 && img.description.length < maxLengthOfDescription) {
			addImageToCurrentIncident(imgArchive, img, toast, history)
		}
	}

	return (
		<>
			<div className="w-10/12 max-w-md m-auto ">
				<div>
					<div className=" p-4 flex justify-center space-x-8">
						<div
							className=" cursor-pointer 
              font-semibold  border-b-2 border-primary
          ">
							Add Image to Current Incident
						</div>
					</div>
					<div className="flex flex-col">
						<div>

							<img src={imgData} alt="" />
							<div className={`border-2 bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 ${errors.description && "border-red-400 focus:border-red-500"
								}`}>
								<div className="flex justify-between" >
									<label
										className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
										htmlFor="description"
									>
										description
									</label>
									<span
										className={`text-xs select-none  font-semibold placeholder-gray-gray4 px-2 pt-1.5 ${img.description.length > maxLengthOfDescription
											? "text-red-500"
											: "text-text"
											}`}
									>
										{`${img.description.length}/ ${maxLengthOfDescription} `}
									</span>
								</div>
								<textarea
									name="description"
									value={img.description}
									onChange={handleChange}
									cols="25"
									rows="7"
									className={`w-full px-2 pb-1.5 bg-gray-100 outline-none focus:border-blue-500 font-light rounded-md `}
								></textarea>
							</div>
							{errors.img && (
								<p className=" text-red-400  text-xs font-medium	 ">
									{errors.img}
								</p>
							)}
							{errors.description && (
								<p className=" text-red-400  text-xs font-medium	 ">
									{errors.description}
								</p>
							)}
						</div >
						<form
							encType="multipart/form-data"
							className=" flex justify-between space-x-2 my-2 "
						>
							<label
								htmlFor="file"
								className={` bg-green-400 hover:bg-green-300  
							}  cursor-pointer block  text-green-900  py-2 px-6 rounded font-bold`}
							>
								<Camera />
							</label>
							<input
								id="file"
								className=" hidden  "
								type="file"
								accept=".jpg, .jpeg, .png"
								onChange={async (e) => {
									let image = e.target.files[0];
									const options = {
										quality: 0.5,
										maxSizeMB: 0.1,
										maxWidthOrHeight: 1920,
										useWebWorker: true,
									};
									const compressedFile = await imageCompression(image, options);
									setImgArchive(compressedFile);
									const reader = new FileReader();
									reader.addEventListener("load", () => {
										setImgData(reader.result);
									});
									reader.readAsDataURL(compressedFile);
								}}
							/>

							<button
								type="submit"
								onClick={(e) => {
									e.preventDefault()
									handleSubmit()
								}}
								className="bg-green-400 hover:bg-green-300 cursor-pointer  text-gray-700  py-2 px-6 rounded font-bold max-w-[6rem] w-full"
							>
								Save
							</button>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}

export default CreateImageIncident;
