import { useToast } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react'
import { Edit, Save, Trash2, X } from 'react-feather';
import { deleteIncidentsVideo, editIncidentsVideo } from '../../helperFunctions/APICallerFunctions';
import { AdminContext } from '../../AdminContext';
function VideoArchive({ videoArchives, editIncident, }) {
	const [videoArchivesVideos, setVideoArchivesVideos] = useState([]);
	const [currentVideo, setCurrentVideo] = useState();
	const [isEditing, setIsEditing] = useState(false);
	const { isAdminAuth } = useContext(AdminContext);
	const toast = useToast()
	const [video_id, setVideo_id] = useState(null);
	useEffect(() => {
		setVideoArchivesVideos(videoArchives?.videos);
	}, [videoArchives]);

	const handleInputChange = (e) => {
		const { name, value } = e.target
		setCurrentVideo({
			...currentVideo,
			[name]: value
		})
	}

	return (
		<div className="grid md:grid-cols-2 xl:grid-cols-3 max-w-sm md:max-w-5xl   gap-4 ">
			{
				videoArchivesVideos?.length > 0 ? videoArchivesVideos?.map((video, idx) => {
					return <div key={idx} className='flex w-full max-w-xs  justify-between flex-col rounded-md border  drop-shadow-xl bg-white p-4 ' >
						<div className="flex flex-col  gap-4   ">
							{isEditing && video_id === video._id ? <div>
								<input
									className="bg-gray-50 outline-none "
									value={currentVideo.video_link}
									name="video_link"
									onChange={handleInputChange}
								/>

								<textarea
									value={currentVideo.description}
									className=" bg-gray-50 text-tiny w-full font-semibold text-text "
									cols="25"
									rows="8"
									name="description"
									maxLength="280"
									onChange={handleInputChange}
								></textarea>
							</div> :
								<>
									<div>
										<p className=" text-gray-800">{video.description}</p>
									</div>
									<iframe title={video.description} src={video.video_link} frameborder="0" ></iframe>
								</>
							}
						</div>
						{isAdminAuth && editIncident && <>

							{
								isEditing && video_id === video._id ? <div className="flex justify-between ">
									<div
										onClick={() => editIncidentsVideo(videoArchivesVideos, idx, currentVideo, setVideoArchivesVideos, setIsEditing, toast)}
										className="flex justify-center items-center cursor-pointer hover:bg-gray-100 py-1 px-2 rounded  "
									>
										<Save className=" text-green-500 " />
									</div>
									<div
										onClick={() => setIsEditing(false)}
										className="flex justify-center items-center cursor-pointer hover:bg-gray-100 py-1 px-2 rounded"
									>
										<X className=" text-red-500 " />
									</div>
								</div> : <div className="flex justify-between">
									<div
										onClick={() => {
											setCurrentVideo(video)
											setVideo_id(video["_id"])
											setIsEditing(true)
										}}
										className="flex justify-center items-center cursor-pointer  hover:bg-gray-100 py-1 px-2 rounded  "
									>
										<Edit className=" text-button " />
									</div>
									<div
										onClick={() => {
											deleteIncidentsVideo(video.incident_id, video._id, idx, setVideoArchivesVideos, videoArchivesVideos, toast)
										}}
										className="flex justify-center items-center cursor-pointer hover:bg-gray-100 py-1 px-2 rounded"
									>
										<Trash2 className=" text-red-500 " />
									</div>
								</div>
							}
						</>}
					</div>
				}) : <>
					<span></span>
					<p className='text-center text-gray-600' >Does not include Videos Currently</p>
					<span></span>
				</>
			}
		</div>
	)
}

export default VideoArchive