import { useContext } from "react";
import { Edit, Trash2 } from "react-feather";
import { AdminContext } from "../../AdminContext";

function ImageArchive({ img, setShowEditImgCompo, setCurrImg, editIncident, handleDelete }) {
	const { isAdminAuth } = useContext(AdminContext);
	let realImg
	if (typeof img?.img === "string") {
		realImg = img?.img
	} else {
		const base64String = btoa(String.fromCharCode(...new Uint8Array(img?.img?.data?.data)));
		realImg = `data:image/png;base64,${base64String}`
	}

	const { description } = img

	return (
		<div className="flex flex-col justify-between drop-shadow-xl bg-white p-4  rounded-md border  ">
			<div>
				<img src={realImg} alt={description} />
				<p className=" text-gray-800">{description}</p>
			</div>
			{isAdminAuth && editIncident && <>
				<div className="flex justify-between">
					<div
						onClick={() => {
							setCurrImg(img)
							setShowEditImgCompo(true)
						}}
						className="flex justify-center items-center cursor-pointer  hover:bg-gray-100 py-1 px-2 rounded  "
					>
						<Edit className=" text-button " />
					</div>
					<div
						onClick={() => handleDelete(img._id)}
						className="flex justify-center items-center cursor-pointer hover:bg-gray-100 py-1 px-2 rounded"
					>
						<Trash2 className=" text-red-500 " />
					</div>
				</div>
			</>}
		</div>
	)
}

export default ImageArchive