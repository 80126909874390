import { Tabs, TabPanel } from "react-tabs";
import { useState } from "react";
import { ChevronLeft } from "react-feather";
import { useHistory } from "react-router";
import HandleASingleTweetCampaign from "../components/handleASignleTweetCampaign/HandleASingleTweetCampaign";
import { createCampaign } from "../helperFunctions/APICallerFunctions";
import { useToast } from "@chakra-ui/react";

function CreateTwitterCampaign() {
  const [campaign, setCampaign] = useState({
    title: "",
    description: "",
  });
  const [tweetsList, setTweetsList] = useState([{ id: 0, body: "" }]);
  const toast = useToast();
  const [submitted, setIsSubmitted] = useState();
  const [errors, setErrors] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const [uploadingTweetsLoader, setUploadingTweetsLoader] = useState(false);
  let history = useHistory();
  const last = tweetsList.length - 1;
  const characterLimit = {
    title: 60,
    description: 160,
  };
  const [page, setPage] = useState(0);

  function validateCampaignDetails() {
    let errors = {};
    if (!campaign.title) {
      errors.title = "Campaign Title is Required";
    }
    if (campaign.title.length > characterLimit.title) {
      errors.title = "Please try making it less than 25 characters.";
    }
    if (!campaign.description) {
      errors.description = "Campaign description is Required";
    }
    if (campaign.description.length > characterLimit.description) {
      errors.description = "Please try making it less than 90 characters.";
    }
    setErrors(errors);
  }

  const updateTheCurrentTweet = (value, id) => {
    setTweetsList(
      tweetsList.map((tweet) => {
        if (tweet.id === id) {
          return { ...tweet, body: value };
        }
        return tweet;
      })
    );
  };

  const changeCampaignTitleAndDescription = (e) => {
    const { name, value } = e.target;
    setCampaign({
      ...campaign,
      [name]: value,
    });
  };

  const addMoreTweets = () => {
    const newEmptyTweetObj = { id: new Date().getTime().toString(), body: "" };
    setTweetsList([...tweetsList, newEmptyTweetObj]);
  };

  const deleteTweet = (id) => {
    const updatedTweetList = tweetsList.filter((tweet) => tweet.id !== id);
    setTweetsList(updatedTweetList);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (e.keycode === 13) {
      return;
    }
    setIsSubmitted(true);
    const tweetExists = tweetsList.every((tweet) => tweet.body.length !== 0);

    if (tweetExists) {
      setUploadingTweetsLoader(true);
      await createCampaign(
        campaign,
        tweetsList,
        toast,
        history,
        setUploadingTweetsLoader
      );
    }
  };

  const goToNextPage = () => {
    if (page === 1) {
      return;
    } else {
      setPage(1);
    }
  };

  const goToPrevPage = () => {
    if (page === 0) {
      return;
    } else {
      setPage((page) => page - 1);
    }
  };

  const RunCampaign = () => {
    return (
      <div>
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabPanel>
            <div className="flex flex-col  ">
              {tweetsList.map((tweet, idx) => {
                return (
                  <HandleASingleTweetCampaign
                    key={idx}
                    tweet={tweet}
                    idx={idx}
                    last={last}
                    updateTheCurrentTweet={updateTheCurrentTweet}
                    newTweetsListLength={tweetsList.length}
                    deleteTweet={deleteTweet}
                    addMoreTweets={addMoreTweets}
                    submitted={submitted}
                  />
                );
              })}
            </div>
          </TabPanel>
        </Tabs>
        <button
          type="button"
          onClick={uploadingTweetsLoader === false ? handleSubmit : null}
          className={`btn w-full text-center ${
            uploadingTweetsLoader
              ? " opacity-90 cursor-not-allowed disabled:opacity-50 "
              : null
          }`}
        >
          {uploadingTweetsLoader ? (
            <div
              className="w-4 h-4 border-2 loader rounded-full animate-spin mx-auto"
              role="status"
            ></div>
          ) : (
            "Share"
          )}
        </button>
      </div>
    );
  };

  const SelectCampaign = () => {
    return (
      <div>
        <form className="space-y-4 ">
          <div>
            <div
              className={`border-2 bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 ${
                errors.title && "border-red-400 focus:border-red-500"
              }`}
            >
              <div className="flex justify-between">
                <label
                  className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
                  htmlFor="campaignTitle"
                >
                  Campaign Title?
                </label>
                <span
                  className={`text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 ${
                    campaign.title.length > characterLimit.title
                      ? "text-red-500"
                      : null
                  }`}
                >
                  {`${campaign.title.length}/ ${characterLimit.title} `}
                </span>
              </div>

              <input
                className="w-full px-2 pb-1.5 bg-gray-100 outline-none  font-light rounded-md"
                type="text"
                id="campaignTitle"
                name="title"
                value={campaign["title"]}
                onChange={changeCampaignTitleAndDescription}
                required
              />
            </div>
            {errors.title && (
              <p className=" text-red-400  text-xs font-medium	 ">
                {errors.title}
              </p>
            )}
          </div>
          <div>
            <div
              className={`border-2 bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 ${
                errors.description && "border-red-400 focus:border-red-500"
              }`}
            >
              <div className="flex justify-between">
                <label
                  className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
                  htmlFor="description"
                >
                  Add Description about your Campaign!
                </label>
                <span
                  className={`text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 ${
                    campaign.description.length > characterLimit.description
                      ? "text-red-500"
                      : null
                  }`}
                >
                  {`${campaign.description.length}/ ${characterLimit.description} `}
                </span>
              </div>
              <textarea
                id="description"
                name="description"
                className="w-full px-2 pb-1.5 bg-gray-100 outline-none focus:border-blue-500 font-light rounded-md"
                cols="25"
                rows="2"
                value={campaign["description"]}
                onChange={changeCampaignTitleAndDescription}
              ></textarea>
            </div>
            {errors.description && (
              <p className=" text-red-400  text-xs font-medium	 ">
                {errors.description}
              </p>
            )}
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      <div className="w-10/12 max-w-md m-auto ">
        <ChevronLeft
          onClick={() => history.goBack()}
          className=" border my-6 cursor-pointer hover:bg-gray-100 w-7 h-7 rounded-full p-1"
        />
      </div>
      <div className="flex items-center justify-center  max-w-3xl m-auto">
        <div className="bg-white px-8  max-w-lg  w-full ">
          <div className="flex justify-between my-4 ">
            <div className="flex justify-between items-center gap-2 ">
              <span
                className={` ${
                  page === 0 || page === 1 ? " bg-blue-800 " : "bg-blue-100 "
                } py-1 px-3 rounded-full  `}
              >
                1
              </span>
              <div className=" text-dark text-tiny ">
                Select
                <p className=" hidden md:inline-block px-1">Campaign</p>
              </div>
            </div>
            <div className="flex justify-between items-center gap-2 ">
              <span
                className={` ${
                  page === 1 ? " bg-blue-800 " : "bg-blue-100 "
                }  py-1 px-3 rounded-full bg-blue-100  `}
              >
                2
              </span>
              <div className=" text-dark text-tiny ">
                Create
                <span className=" hidden md:inline-block px-1">Campaign</span>
              </div>
            </div>
          </div>
          {page === 1 && <div className="divider"></div>}
          {page === 0 ? SelectCampaign() : null}
          {page === 1 ? RunCampaign() : null}
          <button
            className="  text-text capitalize  border-2 border-gray-200 my-4  py-2 px-4  rounded font-semibold justify-self-end "
            onClick={() => {
              if (page === 0) {
                if (
                  !campaign.title ||
                  !campaign.description ||
                  campaign.description.length > characterLimit.description ||
                  campaign.title.length > characterLimit.title
                ) {
                  validateCampaignDetails();
                } else {
                  goToNextPage();
                }
              } else {
                goToPrevPage();
              }
            }}
          >
            {page === 0 ? "next" : "prev"}
          </button>
        </div>
      </div>
    </>
  );
}

export default CreateTwitterCampaign;
