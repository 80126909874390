import { useToast } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react'
import { Edit, Trash2, X } from 'react-feather';
import { deleteIncidentMetadata, editIncidentMetadata, fetchIncident } from '../../helperFunctions/APICallerFunctions'
import { AdminContext } from '../../AdminContext';

export default function IncidentCard({ fetchedIncidents, editIncident, setFetchedIncidents, currentIncidentId }) {
	const [incidents, setIncidents] = useState([]);
	const [currentIncident, setCurrentIncident] = useState(null);
	const [isEditing, setIsEditing] = useState(null);
	const { isAdminAuth } = useContext(AdminContext);
	const toast = useToast()


	useEffect(() => {
		setIncidents(fetchedIncidents);
	}, []);


	function EditIncident({ currentIncident }) {
		const [incident, setIncident] = useState(currentIncident);
		const [errors, setErrors] = useState({});
		const characterLimit = {
			description: 90,
			title: 25,
		};

		const changeIncidentDetails = (e) => {
			const { name, value } = e.target;
			setIncident({
				...incident,
				[name]: value,
			});
		};

		return (
			<>
				<div className="overlay"></div>
				<div className=" bg-white fixed lg:max-w-md lg:w-full m-auto sm:left-[35%] top-5 sm:top-20  z-50 p-4 rounded-md " >
					<div >

						<X
							onClick={() => setIsEditing(false)}
							className=" border my-6 cursor-pointer hover:bg-gray-100 w-7 h-7 rounded-full p-1"
						/>
					</div>
					<div className="flex items-center justify-center  max-w-3xl m-auto">
						<div className="bg-white px-8  max-w-lg  w-full ">
							<div>
								<form className="space-y-4 ">
									<div>
										<div
											className={`border-2 bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 ${errors.title && "border-red-400 focus:border-red-500"
												}`}
										>
											<div className="flex justify-between">
												<label
													className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
													htmlFor="campaignTitle"
												>
													Event Title?
												</label>
												<span
													className={`text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 ${incident.title.length > characterLimit.title
														? "text-red-500"
														: null
														}`}
												>
													{`${incident.title.length}/ ${characterLimit.title} `}
												</span>
											</div>

											<input
												className="w-full px-2 pb-1.5 bg-gray-100 outline-none  font-light rounded-md"
												type="text"
												id="campaignTitle"
												name="title"
												value={incident["title"]}
												onChange={changeIncidentDetails}
												required
											/>
										</div>
										{errors.title && (
											<p className=" text-red-400  text-xs font-medium	 ">
												{errors.title}
											</p>
										)}
									</div>
									<div>
										<div
											className={`border-2 bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 ${errors.description && "border-red-400 focus:border-red-500"
												}`}
										>
											<div className="flex justify-between">
												<label
													className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
													htmlFor="description"
												>
													Add Description about your Event!
												</label>
												<span
													className={`text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 ${incident.description.length > characterLimit.description
														? "text-red-500"
														: null
														}`}
												>
													{`${incident.description.length}/ ${characterLimit.description} `}
												</span>
											</div>

											<textarea
												id="description"
												name="description"
												className="w-full px-2 pb-1.5 bg-gray-100 outline-none focus:border-blue-500 font-light rounded-md"
												cols="25"
												rows="2"
												value={incident["description"]}
												onChange={changeIncidentDetails}
											></textarea>
										</div>
										{errors.description && (
											<p className=" text-red-400  text-xs font-medium	 ">
												{errors.description}
											</p>
										)}
									</div>
									<div>
										<div
											className={`border-2 bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 ${errors.location && "border-red-400 focus:border-red-500"
												}`}
										>
											<div className="flex justify-between">
												<label
													className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
													htmlFor="location"
												>
													Incident location?
												</label>
											</div>

											<input
												className="w-full px-2 pb-1.5 bg-gray-100 outline-none  font-light rounded-md"
												type="text"
												id="location"
												name="location"
												value={incident["location"]}
												onChange={changeIncidentDetails}
												required
											/>
										</div>
										{errors.location && (
											<p className=" text-red-400  text-xs font-medium	 ">
												{errors.location}
											</p>
										)}
									</div>
									<div>
										<div
											className={`border-2 bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 ${errors.title && "border-red-400 focus:border-red-500"
												}`}
										>
											<div className="flex justify-between">
												<label
													className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
													htmlFor="date_of_incident"
												>
													Date of Incident?
												</label>
											</div>

											<input
												className="w-full px-2 pb-1.5 bg-gray-100 outline-none  font-light rounded-md"
												type="date"
												id="date_of_incident"
												name="date_of_incident"
												value={incident["date_of_incident"]}
												onChange={changeIncidentDetails}
												required
											/>
										</div>
										{errors.date_of_incident && (
											<p className=" text-red-400  text-xs font-medium	 ">
												{errors.date_of_incident}
											</p>
										)}
									</div>
									<div className="grid grid-cols-3 gap-2" >
										<div>
											<div
												className={`border-2 bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 ${errors.number_of_displaced && "border-red-400 focus:border-red-500"
													}`}
											>
												<div className="flex justify-between">
													<label
														className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
														htmlFor="number_of_displaced"
													>
														Displaced ?
													</label>

												</div>

												<input
													className="w-full px-2 pb-1.5 bg-gray-100 outline-none  font-light rounded-md"
													type="number"
													id="number_of_displaced"
													name="number_of_displaced"
													onChange={changeIncidentDetails}
													value={incident["number_of_displaced"]}
													required
												/>
											</div>
											{errors.number_of_displaced && (
												<p className=" text-red-400  text-xs font-medium	 ">
													{errors.number_of_displaced}
												</p>
											)}
										</div>
										<div>
											<div
												className={`border-2 bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 ${errors.number_of_injured && "border-red-400 focus:border-red-500"
													}`}
											>
												<div className="flex justify-between">
													<label
														className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
														htmlFor="number_of_injured"
													>
														Injured ?
													</label>

												</div>

												<input
													className="w-full px-2 pb-1.5 bg-gray-100 outline-none  font-light rounded-md"
													type="number"
													id="number_of_injured"
													name="number_of_injured"
													value={incident["number_of_injured"]}
													onChange={changeIncidentDetails}
													required
												/>
											</div>
											{errors.number_of_injured && (
												<p className=" text-red-400  text-xs font-medium	 ">
													{errors.number_of_injured}
												</p>
											)}
										</div>
										<div>
											<div
												className={`border-2 bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 ${errors.number_of_killed && "border-red-400 focus:border-red-500"
													}`}
											>
												<div className="flex justify-between">
													<label
														className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
														htmlFor="number_of_killed"
													>
														Killed ?
													</label>
												</div>

												<input
													className="w-full px-2 pb-1.5 bg-gray-100 outline-none  font-light rounded-md"
													type="number"
													id="number_of_killed"
													name="number_of_killed"
													value={incident["number_of_killed"]}
													onChange={changeIncidentDetails}
													required
												/>
											</div>
											{errors.number_of_killed && (
												<p className=" text-red-400  text-xs font-medium	 ">
													{errors.number_of_killed}
												</p>
											)}
										</div>
									</div>
								</form>
							</div>
							<button
								className="   text-text capitalize  border-2 border-gray-200 my-4  py-2 px-4  rounded font-semibold justify-self-end "
								onClick={() => editIncidentMetadata(incident, setIsEditing, setIncident, incidents, setIncidents, toast)}

							>
								Edit Incident
							</button>
						</div>
					</div>
				</div ></>
		);
	}

	return (
		<>

			{incidents?.map((incident, index) => {
				return <div key={index} onClick={() => {
					fetchIncident(incident._id, setFetchedIncidents, toast)
				}
				} className=" cursor-pointer border shadow-main  p-6 space-y-4 rounded-xl flex flex-col " >
					<div className="flex items-center justify-between">
						<p className=" font-bold text-dark ">
							{incident.title}
						</p>
						{incident._id === currentIncidentId && <p className=" font-bold text-xs text-dark bg-green-300 px-2 py-1  rounded-md ">
							Current
						</p>}
					</div>
					<p className=" text-primary text-xs ">
						Date :{" "}
						{incident.date_of_incident}
					</p>
					<p className="text-tiny font-semibold text-text  my-4 ">
						{incident.description}
					</p>

					{/* {isAdminAuth && editIncident && <div className="flex justify-between ">
						<div
							onClick={() => {
								setCurrentIncident(incident)
								setIsEditing(true)
							}}
							className="flex justify-center items-center cursor-pointer  hover:bg-gray-100 py-1 px-2 rounded  "
						>
							<Edit className=" text-button " />
							<p>Edit</p>
						</div>
						<div
							onClick={() => deleteIncidentMetadata(incident._id, index, incidents, setIncidents, toast)}
							className="flex justify-center items-center cursor-pointer hover:bg-gray-100 py-1 px-2 rounded"
						>
							<Trash2 className=" text-red-500 " />
							<p>Delete</p>
						</div>
					</div>} */}

				</div>
			})}

			{isEditing && <EditIncident currentIncident={currentIncident} />}
		</>
	)
}



