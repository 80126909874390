import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom'
import { AdminContext } from '../AdminContext';
const PrivateRoute = ({ children, ...rest }) => {
	const { isAdminAuth } = useContext(AdminContext);
	return (
		<Route {...rest}>
			{!isAdminAuth
				?
				<Redirect to="/SignIn" />
				:
				children}
		</Route>
	)
}

export { PrivateRoute }