import { useState } from "react";
import { Facebook, Instagram, Menu, Twitter, UserPlus, X } from "react-feather";
import { Link } from "react-router-dom";

function Footer() {
  const [menu, setMenu] = useState(true);

  return (
    <div className="mx-auto mt-20 py-10 px-6   bg-gray-100 grid grid-cols-3 ">
      <div></div>
      <div className="flex flex-col items-center gap-6 " >
        <h1 className=" text-xl sm:text-2xl md:text-3xl font-bold " >
          Follow Us
        </h1>
        <div className="flex justify-between gap-6">
          <a href="https://www.facebook.com/FanoCyber/" target="_blank" rel="noreferrer">
            <Facebook className=" stroke-blue-900 " />
          </a>
          <a href="https://twitter.com/Cyber_Fano" target="_blank" rel="noreferrer">
            <Twitter className=" stroke-blue-500" />
          </a>
          <a href="https://www.instagram.com/cyber_fano/" target="_blank" rel="noreferrer">
            <Instagram className=" stroke-pink-500" />
          </a>
        </div>
      </div>
      <div className="flex flex-col items-end " >
        <div className="  flex items-center ">
          <button onClick={() => setMenu(!menu)}>
            {menu ? (
              <UserPlus className="w-3 h-3" />
            ) : (
              <X className=" w-3 h-3" />
            )}
          </button>
        </div>
        <div
          className={` ${menu && "hidden"
            }`}
        >
          {[{
            link: "Sign Up",
            url: "SignUp",
          },
          {
            link: "Sign In",
            url: "SignIn",
          }].map((item, index) => {
            return (
              <Link
                key={index}
                className="block py-2 px-4 text-sm capitalize text-dark hover:bg-gray-200"
                to={`/${item.url}`}
                onClick={() => setMenu(!menu)}
              >
                {item.link}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Footer;
