import React, { useState } from "react";
import DonateCard from "../components/Donate/DonateCard";
import MemberShipCard from "../components/Donate/MemberShipCard";
import { useHistory } from "react-router-dom";

function Donate() {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className=" lg:flex m-auto w-fit space-y-4 lg:space-y-0 my-4 justify-center gap-8">
        <MemberShipCard />
        <DonateCard setShow={setShow} />
      </div>
      {show && <DonationsBox />}
    </>
  );
}

export default Donate;

const DonationsBox = () => {
  const [amount, setAmount] = useState(10);
  let history = useHistory();

  return (
    <div className=" z-50 ">
      <div className="overlay"></div>
      <div className=" flex justify-center items-center bg-gray-400  ">
        <div className=" fixed top-20 sm:top-36  z-50  bg-white px-8 py-4 pb-8 md:rounded-md  w-11/12 sm:w-full max-w-sm   border border-blue-500">
          <div className="space-y-6">
            <div className=" w-full flex flex-col gap-4 justify-center items-center "></div>
            <div>
              <label
                className=" text-xs block mb-1  text-text font-semibold"
                htmlFor="donationAmount"
              >
                Enter Your Amount :
              </label>
              <input
                className=" outline-none border-2 border-transparent focus:border-blue-500 bg-gray-100 rounded-md p-3 w-full "
                type="number"
                id="donationAmount"
                name="donationAmount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>

            <button
              onClick={() => {
                if (!amount) return;
                localStorage.setItem("amount", JSON.stringify(amount));
                history.push("payment");
              }}
              type="button"
              className="transform active:scale-90 duration-75 block text-center btn w-full my-6"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
