import React, { useContext, useEffect, useState } from 'react'
import { Edit, Save, Trash2, X } from 'react-feather';
import Badge from '../components/User/Badge';
import { deleteEmailCampaign, fetchEmailCampaignDetails, updateEmailCampaignMetadataAPI } from "../helperFunctions/APICallerFunctions";
import { AdminContext } from '../AdminContext';
import Input from '../components/Input';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import {
	fetchCurrentEmailCampaign,
	fetchOldEmailCampaigns,
	fetchRecentEmailCampaigns,
} from '../helperFunctions/APICallerFunctions'
import { useToast } from '@chakra-ui/react';

function EmailsCampaign() {


	const toast = useToast()
	const { isAdminAuth } = useContext(AdminContext);
	const [currentEmailCampaign, setCurrentEmailCampaign] = useState({});
	const [renderedCurrentEmailCampaign, setRenderedCurrentEmailCampaign] = useState({});
	const [renderedRecentEmailCampaigns, setRenderedRecentEmailCampaigns] = useState([]);
	const [oldEmailCampaigns, setOldEmailCampaigns] = useState([]);
	const [isEditing, setIsEditing] = useState(false);
	const [isEditingMetaData, setIsEditingMetaData] = useState(false);
	const [currentEmailCampId, setCurrentEmailCampId] = useState(false);

	useEffect(() => {
		fetchCurrentEmailCampaign(setCurrentEmailCampaign, toast)
		fetchRecentEmailCampaigns(setRenderedRecentEmailCampaigns, toast)
	}, [])


	localStorage.setItem(
		"currentCampaignId",
		JSON.stringify(currentEmailCampaign?._id)
	);


	useEffect(() => {
		setRenderedCurrentEmailCampaign(currentEmailCampaign);
		// setCurrentEmailCampaignId(currentEmailCampaign && currentEmailCampaign?._id);
	}, [currentEmailCampaign]);

	const [emailUserName, setEmailUserName] = useState("");
	const [show, setShow] = useState(true);

	const EmailCampaignCard = ({ emailCamp, index, renderedRecentEmailCampaigns, setRenderedRecentEmailCampaigns }) => {
		const [emailMetaData, setEmailMetaData] = useState(emailCamp);

		const handleMetaDataChange = (e) => {
			const { name, value } = e.target
			setEmailMetaData({
				...emailMetaData,
				[name]: value,
			});
		}

		return <div
			onClick={() => fetchEmailCampaignDetails(emailCamp._id, setRenderedCurrentEmailCampaign, toast)
			}
			className=" hover:cursor-pointer border shadow-main  my-4 p-6 space-y-4 rounded-xl flex flex-col " >
			{
				isEditingMetaData && emailMetaData._id === currentEmailCampId ?
					<input
						className="bg-gray-50 outline-none "
						name="title"
						value={emailMetaData.title}
						onChange={handleMetaDataChange}
					/> : <p className=" font-bold text-dark "> {emailMetaData.title} </p>
			}
			{
				isEditingMetaData && emailMetaData._id === currentEmailCampId ?
					(
						<div>
							<textarea
								value={emailMetaData.description}
								className=" bg-gray-50 text-tiny w-full font-semibold text-text "
								cols="25"
								name="description"
								rows="8"
								maxLength="280"
								onChange={handleMetaDataChange}
							></textarea>
						</div>
					) : (
						<p className="text-tiny font-semibold text-dark my-4 ">
							{emailMetaData.description}
						</p>
					)}
			<div>
			</div>
		</div>
	}

	return <>
		<div className="my-4 max-w-7xl m-auto ">
			{show ? (
				<div>
					<div className="overlay"></div>
					<div className=" flex justify-center items-center bg-gray-400  ">
						<div className=" fixed top-20 sm:top-36  z-50  bg-white px-8 py-4 pb-8 md:rounded-md  w-11/12 sm:w-full max-w-sm   border border-blue-500">
							<div
								onSubmit={(e) => {
									e.preventDefault();
									setShow(false);
								}}
								className="space-y-6"
							>
								<div className=" w-full flex flex-col gap-4 justify-center items-center "></div>
								<div>
									<label
										className=" text-xs block mb-1  text-text font-semibold"
										htmlFor="name"
									>
										Enter name
									</label>
									<input
										className=" outline-none border-2 border-transparent focus:border-blue-500 bg-gray-100 rounded-md p-3 w-full "
										type="text"
										id="name"
										name="name"
										value={emailUserName}
										onChange={(e) => setEmailUserName(e.target.value)}
									/>
								</div>
								<button
									onClick={(e) => {
										emailUserName !== "" && setShow(false)
									}}
									type="button"
									className="transform active:scale-90 duration-75 block text-center btn w-full my-6"
								>
									Save
								</button>
							</div>
						</div>
					</div>
				</div>
			) : null}
			<div >
				{renderedCurrentEmailCampaign ? (
					<div className='border rounded-md space-y-4 p-4 '>
						<div className="grid    md:grid-cols-2 xl:grid-cols-3  gap-6 m-auto   ">
							<div className=" max-w-md ">
								<div className="flex gap-6 items-center" >
									<Badge
										title={
											renderedCurrentEmailCampaign ? renderedCurrentEmailCampaign.title : null
										}
									/>
									{isAdminAuth && <div onClick={() => setIsEditing(true)} className=" cursor-pointer hover:bg-gray-100 border p-1.5 rounded text-gray-500  " >
										<Edit />
									</div>}
								</div>
								<p className=" text-grey " >{renderedCurrentEmailCampaign.description}</p>
							</div>
						</div>
						<div className='divider' ></div>
						<div>
							<div className=" m-auto h-auto w-full  flex flex-col justify-between space-y-4  ">
								<div className=' bg-gray-100 p-4 space-y-2' >
									<p>
										<strong>	to : </strong>
										{renderedCurrentEmailCampaign.to?.join(", ")}
									</p>
									<p>
										<strong>Subject : </strong>
										{renderedCurrentEmailCampaign.subject}
									</p>
								</div>
								<div className="  text-text break-words ">
									<p>
										{renderedCurrentEmailCampaign?.body?.split(/\n\n|\r/gm).map(line => <>{line}<br /></>)}
									</p>
									<p className=' my-4 ' >
										With regards,
										<p className='font-semibold' >
											{emailUserName}
										</p>
									</p>
								</div>

							</div>
							{isAdminAuth && <div className="flex justify-between ">
								{/* <div
									onClick={() => setIsEditing(true)}
									className="flex justify-center items-center cursor-pointer  hover:bg-gray-100 py-1 px-2 rounded  "
								>
									<Edit className=" text-button " />
									<p>Edit</p>
								</div> */}
								<div onClick={() => {
									deleteEmailCampaign(renderedCurrentEmailCampaign._id, setCurrentEmailCampaign, fetchCurrentEmailCampaign, toast)
								}
								}
									className="flex justify-center items-center cursor-pointer hover:bg-gray-100 py-1 px-2 rounded"
								>
									<Trash2 className=" text-red-500 " />
									<p>Delete</p>
								</div>
							</div>}
						</div>
					</div>
				) : null}
			</div>
			{renderedCurrentEmailCampaign && (
				<div className="grid  md:grid-cols-2 xl:grid-cols-3 max-w-5xl gap-6 m-auto mt-4   ">
					{renderedRecentEmailCampaigns && <Badge text="Campaigns" />}
				</div>
			)}
			<div className="grid  md:grid-cols-2 xl:grid-cols-3 max-w-5xl gap-6 m-auto">
				{currentEmailCampaign && (
					<div
						className=" border shadow-main   cursor-pointer my-4 p-6 space-y-4 rounded-xl  "
						onClick={() => {
							setRenderedCurrentEmailCampaign(currentEmailCampaign);
							window.scrollTo({
								top: 0,
								behavior: "smooth",
							});
						}}
					>
						<div className=" m-auto w-full  sm:max-w-xs   cursor-pointer ">
							<div>
								<div className="flex items-center justify-between">
									<p className=" font-bold text-dark ">
										{currentEmailCampaign.title}
									</p>
									<p className=" font-bold text-xs text-dark bg-green-300 px-2 py-1  rounded-md ">
										Current
									</p>
								</div>

								<p className="text-tiny font-semibold text-dark my-4 ">
									{currentEmailCampaign.description}
								</p>
							</div>
						</div>
					</div>
				)}
				{
					renderedRecentEmailCampaigns?.map((emailCamp, index) => {
						return <EmailCampaignCard key={index} emailCamp={emailCamp} index={index} renderedRecentEmailCampaigns={renderedRecentEmailCampaigns} setRenderedRecentEmailCampaigns={setRenderedRecentEmailCampaigns} />
					})}
				{renderedRecentEmailCampaigns.length === 5 && oldEmailCampaigns.length < 1 && <button
					onClick={() => fetchOldEmailCampaigns(setOldEmailCampaigns, toast)}
					className='btn h-max  block'>Load More Email Campaigns</button>}
				{
					oldEmailCampaigns.length > 0 && oldEmailCampaigns?.map((camp, index) => {
						return (
							<EmailCampaignCard emailCamp={camp} index={index} key={index} renderedRecentEmailCampaigns={oldEmailCampaigns} setRenderedRecentEmailCampaigns={setOldEmailCampaigns} />
						);
					})}
			</div>
		</div>
		{isEditing && <EditEmailMetadata emailCampaign={renderedCurrentEmailCampaign} setIsEditing={setIsEditing} setRenderedCurrentEmailCampaign={setRenderedCurrentEmailCampaign} />}
	</>
}
export default React.memo(EmailsCampaign)

function EditEmailMetadata({ emailCampaign, setIsEditing, setRenderedCurrentEmailCampaign }) {
	const toast = useToast()

	const formSchema = Yup.object().shape({
		title: Yup.string()
			.required("Title is mandatory")
			.max(60, "Title Cannot be more than 60 Characters"),
		description: Yup.string()
			.required("description is mandatory")
			.max(160, "description Cannot be more than 160 Characters"),
	});

	const formOptions = { resolver: yupResolver(formSchema) };
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm(formOptions);


	return (
		<div >
			<div className="overlay"></div>
			<div className="flex items-center justify-center  m-auto  ">
				<div className="bg-white p-4 rounded  max-w-md  w-full  fixed top-10   z-50 ">
					<div>
						<div className="flex flex-col  ">
							<div className="my-2">
								<X
									onClick={() => setIsEditing(false)}
									className=" border cursor-pointer hover:bg-gray-100 w-7 h-7 rounded-full p-1"
								/>
								<div className=' space-y-4 py-2 ' >
									<Input
										label="Title"
										type="text"
										name="title"
										register={register}
										errors={errors}
										defaultValue={emailCampaign?.title}
									/>
									<Input
										label="Description"
										type="text"
										name="description"
										register={register}
										errors={errors}
										defaultValue={emailCampaign?.description}

									/>
								</div>
							</div>
						</div>
						<button
							type="button"
							onClick={handleSubmit(async data => {
								await updateEmailCampaignMetadataAPI(
									{ ...data, email_campaign_id: emailCampaign["_id"] },
									setIsEditing,
									setRenderedCurrentEmailCampaign, toast
								)
							})}
							className={`btn w-full text-center`}
						>
							Share
						</button>
					</div >
				</div>
			</div>
		</div >
	);
}

