import React from "react";
import king from "../../assets/king.png";

function DonateCard({ setShow }) {
  return (
    <div className="flex flex-col justify-between  drop-shadow-xl bg-white p-4 max-w-xs rounded-md border gap-4 ">
      <div className=" space-y-2 ">
        <img src={king} width="300" alt="movements" />
        <div>
          <p className=" text-gray-900">
            Donate and help our effort to build archives for the victims of
            AmharaGenocide. The donation is used to rent server and database for
            graphic based evidences.
          </p>
        </div>
      </div>
      <div className="flex justify-end ">
        <button onClick={() => setShow(true)} className="btn-secondary">
          Donate
        </button>
      </div>
    </div>
  );
}

export default DonateCard;
