import React, { useContext, useEffect, useState } from 'react'
import { Edit, Trash2, X } from 'react-feather'
import { AdminContext } from '../../AdminContext';
import { deleteIncidentsArticle, editIncidentsArticle } from "../../helperFunctions/APICallerFunctions";
import { useToast } from "@chakra-ui/react";

function ArticleArchive({ articles: fetchedArticles, incident_id, editIncident }) {
	const [articles, setArticles] = useState([]);
	const [currentArticle, setCurrentArticle] = useState();
	const [isEditing, setIsEditing] = useState(false);
	const { isAdminAuth } = useContext(AdminContext);
	const toast = useToast()

	useEffect(() => {
		setArticles(fetchedArticles);
	}, []);

	function EditArticleIncident({ thisArticle }) {
		const [article, setArticle] = useState(thisArticle)
		const [errors, setErrors] = useState({});
		const maxLengthOfDescription = 160

		const handleInputChange = (e) => {
			const { name, value } = e.target;
			setArticle({
				...article,
				[name]: value,
			});
		};

		const handleSubmit = () => {
			if (!article.article_link || article.article_link === "") errors.article_link = "Please add article link";
			if (!article.description || article.description === "") errors.description = "Campaign description is Required";
			else if (article.description.length > maxLengthOfDescription)
				errors.description = `Please try making it less than ${maxLengthOfDescription} characters.`;
			else errors.description = "";
			if (article.article_link.length > 0) errors.article_link = "";

			setErrors({ ...errors });

			if (article.article_link !== "" && article.description !== "" && article.description.length < maxLengthOfDescription) {
				editIncidentsArticle(setIsEditing,
					toast,
					setArticle,
					article, articles, setArticles)
			}
		}


		return (
			<div>
				<div className="overlay"></div>
				<div className=" bg-white fixed lg:max-w-md lg:w-full m-auto sm:left-[35%] top-20  z-50 p-4 rounded-md  ">
					<div>
						<X
							onClick={() => setIsEditing(false)}
							className=" border cursor-pointer hover:bg-gray-100 w-7 h-7 rounded-full p-1"
						/>
						<div className="  flex justify-center space-x-8">
							<div
								className=" cursor-pointer 
				  font-semibold  border-b-2 border-primary
			  ">
								Edit Article
							</div>
						</div>
						<div className="flex my-2 gap-2 flex-col">
							<div
								className={`border-2 bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 ${errors.article_link && "border-red-400 focus:border-red-500"
									}`}
							>
								<label
									className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
									htmlFor="article_link"
								>
									Article Link?
								</label>
								<input
									name="article_link"
									value={article.article_link}
									onChange={handleInputChange}
									className="w-full px-2 pb-1.5 bg-gray-100 outline-none  font-light rounded-md"
									type="text"
									id="link"
									required
								/>
							</div>
							{errors.article_link && (
								<p className=" text-red-400  text-xs font-medium	 ">
									{errors.article_link}
								</p>
							)}
							<div className={`border-2 bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 ${errors.description && "border-red-400 focus:border-red-500"
								}`}>
								<div className="flex justify-between" >
									<label
										className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
										htmlFor="description"
									>
										description
									</label>
									<span
										className={`text-xs select-none  font-semibold placeholder-gray-gray4 px-2 pt-1.5 ${article.description.length > maxLengthOfDescription
											? "text-red-500"
											: "text-text"
											}`}
									>
										{`${article.description.length}/ ${maxLengthOfDescription} `}
									</span>
								</div>
								<textarea
									name="description"
									value={article.description}
									onChange={handleInputChange}
									cols="25"
									rows="7"
									className={`w-full px-2 pb-1.5 bg-gray-100 outline-none focus:border-blue-500 font-light rounded-md `}
								></textarea>
							</div>
							{errors.description && (
								<p className=" text-red-400  text-xs font-medium	 ">
									{errors.description}
								</p>
							)}
						</div>
					</div>
					<button
						type="button"
						onClick={() => handleSubmit()}
						className={`btn w-full text-center`}
					>
						Share
					</button>
				</div>
			</div>
		);
	}

	return (
		<>
			{
				articles.map((article, id) => {
					return <div key={id} className=" my-2 m-auto  h-auto   w-full  border hover:bg-gray-50 flex flex-col justify-between  space-y-4 p-6 rounded-xl">
						<p className=" font-semibold text-text break-words ">{article.description}</p>

						{isAdminAuth && editIncident ? <div className="flex justify-between ">
							<div
								onClick={() => {
									setCurrentArticle(article)
									setIsEditing(true)
								}}
								className="flex justify-center items-center cursor-pointer  hover:bg-gray-100 py-1 px-2 rounded  "
							>
								<Edit className=" text-button " />
							</div>
							<div
								onClick={() => deleteIncidentsArticle(incident_id, id, articles, setArticles, article._id, toast)}
								className="flex justify-center items-center cursor-pointer hover:bg-gray-100 py-1 px-2 rounded"
							>
								<Trash2 className=" text-red-500 " />
							</div>
						</div> :
							<a
								href={article.article_link}
								target="_blank"
								rel="noreferrer"
								className="text-xl block bg-button py-2 px-4  rounded text-white font-semibold  hover:bg-hoverButton transition duration-300"
							>
								Read Full Article here!
							</a>

						}
					</div>
				})
			}
			{isEditing && <EditArticleIncident thisArticle={currentArticle} />}
		</>
	)
}

export default ArticleArchive






