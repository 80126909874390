import CampaignCard from "./CampaignCard";
import Badge from "./Badge";
import Tweets from "./Tweets";
import { useContext, useEffect, useState } from "react";
import { Edit2, PlusCircle, Twitter } from "react-feather";
import { AdminContext } from "../../AdminContext";
import { CurrentCampaignIdContext } from "../Context/CurrentCampaignId";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { fetchOldTwitterCampaigns, fetchTwitterCampaignDetails } from "../../helperFunctions/APICallerFunctions";
import { useToast } from "@chakra-ui/react";
import { useHistory } from 'react-router-dom';

function TwitterCampaignDetails({ currentCampaign, recentCampaigns }) {
  const [renderedCurrentCampaign, setRenderedCurrentCampaign] = useState([]);
  const [renderedRecentCampaign, setRenderedRecentCampaign] = useState([]);
  const { setCurrentCampaignId } = useContext(CurrentCampaignIdContext);
  const [edit, setEdit] = useState(true)
  const [oldTwitterCampaigns, setOldTwitterCampaigns] = useState([]);
  const [show, setShow] = useState(true);
  const toast = useToast()
  localStorage.setItem(
    "currentTwitterCampaignId",
    JSON.stringify(currentCampaign?._id)
  );
  const history = useHistory();

  useEffect(() => {
    setRenderedCurrentCampaign(currentCampaign);
    setRenderedRecentCampaign(recentCampaigns);
    setCurrentCampaignId(currentCampaign && currentCampaign?._id);
  }, [currentCampaign, recentCampaigns]);

  const { isAdminAuth } = useContext(AdminContext);
  const showToggle = isAdminAuth && edit


  return (
    <div className="my-4">
      <div className="userProfileDivider "></div>
      <div className=" py-4 grid grid-cols-3 md:mx-24 ">
        <div></div>
        <div></div>
        {(renderedCurrentCampaign && isAdminAuth) ||
          (renderedRecentCampaign && isAdminAuth) ? (
          <div className=" flex items-center justify-end ">
            <div
              onClick={() => setEdit(!edit)}
              className={`   mr-3 bg-gray-200 w-[55%] sm:w-2/6 xl:w-1/6 h-full cursor-pointer rounded-full  transition-all duration-1000 ease-in-out  shadow-inner ${edit && " bg-green-400 "
                }`}
            >
              <div
                className={` toggleCircle transition-all duration-200 ease-out  shadow-main rounded-full bg-gray-900 flex justify-center items-center text-center noselect  ${edit
                  ? "text-green-400 relative -right-1.5 translate-x-full "
                  : "  text-gray-400  "
                  }`}
              >
                <Edit2 className=" scale-75" />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className='max-w-2xl lg:max-w-5xl  m-auto'>
        {renderedCurrentCampaign ? (
          <>
            <div className="  flex justify-between  ">
              <div>
                <p className=" font-bold text-2xl my-2 md:my-4" >{renderedCurrentCampaign.title}</p>
                <p className=" text-grey text-base " >{renderedCurrentCampaign.description}</p>
              </div>
              {isAdminAuth ? (
                <div className="mt-4 ">
                  <Link
                    to="/addTweetsToCurrentCampaign"
                  >
                    <PlusCircle className=" cursor-pointer  text-green-500 " />
                  </Link>
                </div>
              ) : null}
            </div>
            {
              renderedCurrentCampaign?.tweets?.length > 0 && (
                <div className=" mb-4 ">
                  <Tweets
                    fetchedTweets={renderedCurrentCampaign[`tweets`]}
                    twitterCampaignId={renderedCurrentCampaign._id}
                    showToggle={showToggle}
                  />
                </div>
              )}
            <div className="userProfileDivider"></div>
          </>
        ) : null}
        {renderedCurrentCampaign && (
          <div className="grid  md:grid-cols-2 xl:grid-cols-3  gap-6 mt-4">
            {renderedRecentCampaign && <Badge text="Campaigns" />}
          </div>
        )}
        <div className="grid justify-between sm:grid-cols-2 sm:gap-4 lg:grid-cols-3  mx-auto w-fit ">
          {currentCampaign && (
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.99 }}
              className=" border shadow-main   cursor-pointer my-4 p-6 space-y-4 rounded-xl  w-full max-w-xs   "
              onClick={() => {
                setRenderedCurrentCampaign(currentCampaign);
              }}
            >
              <div className=" m-auto w-full   cursor-pointer ">
                <div>
                  <div className="flex items-center justify-between">
                    <p className=" font-bold text-dark ">
                      {currentCampaign.title}
                    </p>
                    <p className=" font-bold text-xs text-dark bg-green-300 px-2 py-1  rounded-md ">
                      Current
                    </p>
                  </div>
                  <p className="text-tiny font-semibold text-dark my-4 ">
                    {currentCampaign.description}
                  </p>
                </div>
              </div>
            </motion.div>
          )}

          {renderedRecentCampaign.length > 0 &&
            renderedRecentCampaign?.map((camp, index) => {
              return (
                <CampaignCard
                  key={camp._id}
                  index={index}
                  campaignMetadata={camp}
                  setRenderedCurrentCampaign={setRenderedCurrentCampaign}
                  renderedRecentCampaign={renderedRecentCampaign}
                  setRenderedRecentCampaign={setRenderedRecentCampaign}
                  showToggle={showToggle}
                />
              );
            })}

          {oldTwitterCampaigns.length < 1 && <button onClick={() => fetchOldTwitterCampaigns(setOldTwitterCampaigns, toast)} className='btn block'>Load More Twitter Campaigns</button>}
          {
            oldTwitterCampaigns.length > 0 && oldTwitterCampaigns?.map((camp, index) => {
              return (
                <div key={index} onClick={() => {
                  fetchTwitterCampaignDetails(camp._id, setRenderedCurrentCampaign,toast,history)
                }
                } className=" hover:cursor-pointer border shadow-main  my-4 p-6 space-y-4 rounded-xl flex flex-col w-full max-w-xs" >
                  <p className=" font-bold text-dark "> {camp.title} </p>
                  <p className="text-tiny font-semibold text-text  my-4 ">
                    {camp.description}
                  </p>
                  <p className="text-2xl">{index + 7}</p>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default TwitterCampaignDetails;
