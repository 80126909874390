import { useState } from "react";
import { useHistory } from "react-router-dom";
import { addTweetsToCurrentCamp } from "../../helperFunctions/APICallerFunctions";
import { useToast } from "@chakra-ui/react";
import AddTweetsToCurrentCampaign from "./AddTweetsToCurrentCampaign";

function AddTweetsToCurrentCampaignPage() {
  let history = useHistory();
  const toast = useToast();
  const id = localStorage.getItem("currentTwitterCampaignId");
  const gotCurrentCampaignId = JSON.parse(id);

  const [tweetsList, setTweetsList] = useState([{ id: 0, body: "" }]);

  const [submitted, setIsSubmitted] = useState();
  const [uploadingTweetsLoader, setUploadingTweetsLoader] = useState(false);
  const last = tweetsList.length - 1;

  const updateTheCurrentTweet = (value, id) => {
    setTweetsList(
      tweetsList.map((tweet) => {
        if (tweet.id === id) {
          return { ...tweet, body: value };
        }
        return tweet;
      })
    );
  };

  const addMoreTweets = () => {
    const newEmptyTweetObj = { id: new Date().getTime().toString(), body: "" };
    setTweetsList([...tweetsList, newEmptyTweetObj]);
  };

  const deleteTweet = (id) => {
    const updatedTweetList = tweetsList.filter((tweet) => tweet.id !== id);
    setTweetsList(updatedTweetList);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (e.keycode === 13) {
      return;
    }
    setIsSubmitted(true);
    const tweetExists = tweetsList.every((tweet) => tweet.body.length !== 0);

    if (tweetExists) {
      setUploadingTweetsLoader(true);
      await addTweetsToCurrentCamp(
        gotCurrentCampaignId,
        tweetsList,
        toast,
        history,
        setUploadingTweetsLoader
      );
    }
  };

  return (
    <>
      <div className="w-10/12 max-w-md m-auto ">
        <div>
          <div className=" p-4 flex justify-center space-x-8">
            <div
              className=" cursor-pointer
              font-semibold  border-b-2 border-primary
          "
            >
              Tweets
            </div>
          </div>
          <div className="flex flex-col  ">
            {tweetsList.map((tweet, idx) => {
              return (
                <AddTweetsToCurrentCampaign
                  key={idx}
                  tweet={tweet}
                  idx={idx}
                  last={last}
                  updateTheCurrentTweet={updateTheCurrentTweet}
                  newTweetsListLength={tweetsList.length}
                  deleteTweet={deleteTweet}
                  addMoreTweets={addMoreTweets}
                  submitted={submitted}
                />
              );
            })}
          </div>
        </div>
        <button
          type="button"
          onClick={uploadingTweetsLoader === false ? handleSubmit : null}
          className={`btn w-full text-center ${
            uploadingTweetsLoader
              ? " opacity-90 cursor-not-allowed disabled:opacity-50 "
              : null
          }`}
        >
          {uploadingTweetsLoader ? (
            <div
              className="w-4 h-4 border-2 loader rounded-full animate-spin mx-auto"
              role="status"
            ></div>
          ) : (
            "Share"
          )}
        </button>
      </div>
    </>
  );
}

export default AddTweetsToCurrentCampaignPage;
