import { useToast } from "@chakra-ui/react";
import { AnimatePresence, } from "framer-motion";
import { useEffect, useState } from "react";
import { Edit, Save, Trash2, X } from "react-feather";
import { deleteTweet, editTweet, } from "../../helperFunctions/APICallerFunctions";
import ConfirmDeleteDialog from "../Alert/ConfirmDeleteDialog";

function Tweets({ fetchedTweets, twitterCampaignId, showToggle, }) {
  const [tweets, setTweets] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editTweetId, setEditTweetId] = useState(null);
  const [newEditedTweetBody, setNewEditedTweetBody] = useState("");
  const [editTweetIndex, setEditTweetIndex] = useState(null);
  const toast = useToast()

  const [isShowingConfirmDeleteComponent, setIsShowingConfirmDeleteComponent] = useState(false)

  const [storeTweetIdAndIndex, setStoreTweetAndIndex] = useState({
    tweetId: "",
    index: "",
  });

  useEffect(() => {
    setTweets(fetchedTweets);
  }, []);

  const handleEditButton = (id, body, index) => {
    setIsEditing(true);
    setEditTweetId(id);
    setEditTweetIndex(index);
    setNewEditedTweetBody(body);
  };
  const generateLink = (tweet) => {
    return "https://twitter.com/intent/tweet?text=" + encodeURIComponent(tweet);
  };

  const handleDeleteButton = async (tweet_id, index) => {
    await deleteTweet(tweet_id, twitterCampaignId, tweets, index, setTweets, toast);
  };

  const canEdit = true

  const updateTweet = async (tweet_id) => {
    await editTweet(
      tweet_id,
      newEditedTweetBody,
      tweets,
      editTweetIndex,
      setTweets,
      setIsEditing,
      toast
    );
  };


  return (
    <>
      {isShowingConfirmDeleteComponent ? (
        <ConfirmDeleteDialog
          confirmDelete={handleDeleteButton}
          idAndIndex={storeTweetIdAndIndex}
          setIsShowingConfirmDeleteComponent={setIsShowingConfirmDeleteComponent}
        />
      ) : null}
      <div className="grid justify-between sm:grid-cols-2 lg:grid-cols-3 ">
        <AnimatePresence initial={false} exitBeforeEnter={true}>
          {
            tweets?.map((tweet, index) => {
              return (
                <a
                  key={index}
                  className=" my-2 m-auto cursor-pointer  h-auto   w-full sm:max-w-xs  border hover:bg-gray-50 flex  flex-col justify-between  space-y-4 p-6 rounded-xl"
                  href={() => {
                    if (isEditing) return
                    else generateLink(tweet["body"])
                  }}
                  target="_blank"
                  rel="noreferrer"
                >

                  {canEdit && isEditing && editTweetId === tweet._id ? (
                    <div>

                      <textarea
                        value={newEditedTweetBody}
                        className=" bg-gray-50 text-tiny w-full font-semibold text-text "
                        cols="25"
                        rows="8"
                        maxLength="280"
                        onChange={(e) =>
                          setNewEditedTweetBody(e.target.value)
                        }
                      ></textarea>
                    </div>
                  ) : !showToggle ? (
                    <a
                      href={generateLink(tweet["body"])}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p className="text-tiny  font-semibold text-text  break-words ">
                        {tweet["body"]}
                      </p>
                    </a>
                  ) : (
                    <p className="text-tiny font-semibold text-text break-words ">
                      {tweet["body"]}
                    </p>
                  )}
                  {canEdit ? (
                    <>
                      {isEditing && editTweetId === tweet._id ? (
                        <div className="flex justify-between ">
                          <div
                            onClick={() => updateTweet(tweet._id)}
                            className="flex justify-center items-center cursor-pointer hover:bg-gray-100 py-1 px-2 rounded  "
                          >
                            <Save className=" text-green-500 " />
                            <p>Save</p>
                          </div>
                          <div
                            onClick={() => setIsEditing(false)}
                            className="flex justify-center items-center cursor-pointer hover:bg-gray-100 py-1 px-2 rounded"
                          >
                            <X className=" text-red-500 " />
                            <p>Cancel</p>
                          </div>
                        </div>
                      ) : showToggle ? (
                        <div className="flex justify-between ">
                          <div
                            onClick={() =>
                              handleEditButton(
                                tweet._id,
                                tweet["body"],
                                index
                              )
                            }
                            className="flex justify-center items-center cursor-pointer  hover:bg-gray-100 py-1 px-2 rounded  "
                          >
                            <Edit className=" text-button " />
                            <p>Edit</p>
                          </div>
                          <div
                            onClick={() => {
                              setIsShowingConfirmDeleteComponent(true);
                              setStoreTweetAndIndex({
                                ...storeTweetIdAndIndex,
                                tweetId: tweet._id,
                                index: index,
                              });
                            }}
                            className="flex justify-center items-center cursor-pointer hover:bg-gray-100 py-1 px-2 rounded"
                          >
                            <Trash2 className=" text-red-500 " />
                            <p>Delete</p>
                          </div>
                        </div>
                      ) : (
                        <div className='flex justify-between items-center'>
                          <p className='text-2xl'>
                            {index + 1}
                          </p>
                          <a
                            href={generateLink(tweet["body"])}
                            target="_blank"
                            rel="noreferrer"
                            className="btn"
                          >
                            Tweet
                          </a>
                        </div>
                      )}
                    </>
                  ) : (
                    <div>
                      <a
                        href={generateLink(tweet["body"])}
                        target="_blank"
                        rel="noreferrer"
                        className="text-xl inline-block bg-button py-2 px-4  rounded text-white font-semibold  hover:bg-hoverButton transition duration-300"
                      >
                        Tweet
                      </a>
                    </div>
                  )}
                </a>
              );
            })}
        </AnimatePresence>
      </div>
    </>
  );
}

export default Tweets;
