import React, { useState } from "react";
import { Eye, EyeOff } from "react-feather";

export default function Input({
    errors,
    register,
    type = "text",
    label = "",
    name = "",
    defaultValue = "",
    ...rest
}) {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div>
            <div
                className={`border-gray-gray4  flex items-center justify-between rounded  border-2 bg-gray-100 transition duration-150 ease-in-out focus-within:border-blue-500  ${errors[name]
                    ? "border-red-500 focus-within:border-red-500"
                    : null
                    } `}
            >
                <div className=" flex-grow ">
                    <label
                        className=" text-bodyTwo block select-none px-2 pt-1.5  font-semibold "
                        htmlFor={name}
                    >
                        {label}
                    </label>
                    <input
                        type={`${showPassword ? "text" : type}`}
                        id={name}
                        name={name}
                        defaultValue={defaultValue}
                        {...register(name)}
                        {...rest}
                        className="w-full rounded-md bg-gray-100 px-2 pb-1.5 font-light outline-none "
                    />
                </div>

                {type === "password" && (
                    <div
                        onClick={() => {
                            setShowPassword(!showPassword);
                        }}
                        className=" cursor-pointer "
                    >
                        {showPassword ? (
                            <Eye className="text-dark scale-75" />
                        ) : (
                            <EyeOff className="text-dark scale-75" />
                        )}
                    </div>
                )}
            </div>
            <div className="text-red-500">
                {errors[name]?.message}
            </div>
        </div>
    );
}
