import React from "react";
import Queen from "../../assets/Queen.png";

function MemberShipCard() {
  return (
    <div className="flex flex-col drop-shadow-xl bg-white p-4 max-w-xs rounded-md border gap-4 ">
      <img src={Queen} width="300" alt="movements" />
      <div>
        <p className=" text-gray-900">
          Subscribe and support our effort. All the payments goes to rent
          servers, domains, and rent database to store our archives.
        </p>
      </div>
      {/* <div className='flex justify-between items-center ' > */}
      <form
        action="https://www.paypal.com/cgi-bin/webscr"
        method="post"
        target="_top"
      >
        <input type="hidden" name="cmd" value="_s-xclick" />
        <input type="hidden" name="hosted_button_id" value="JGVSWM9QZRGVY" />
        {/* <input type="image" src="https://www.paypalobjects.com/en_GB/i/btn/btn_subscribe_LG.gif" border="0" name="submit" alt="PayPal – The safer, easier way to pay online!" /> */}
        <img
          alt=""
          border="0"
          src="https://www.paypalobjects.com/en_GB/i/scr/pixel.gif"
          width="1"
          height="1"
        />
        <div className="flex justify-end ">
          <button className="btn">Subscribe</button>
        </div>
      </form>
      {/* </div> */}
    </div>
  );
}

export default MemberShipCard;
