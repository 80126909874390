import { Link, Redirect, useHistory } from "react-router-dom";
import { ChevronLeft } from "react-feather";
import { useContext, useState } from "react";
import { signUpWithAPI } from "../helperFunctions/APICallerFunctions";
import Input from "../components/Input";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useToast } from "@chakra-ui/react";
import { AdminContext } from "../AdminContext";

function SignUp() {
  let history = useHistory();
  const toast = useToast()
  const [signUpLoader, setSignUpLoader] = useState(false);
  const { isAdminAuth } = useContext(AdminContext);

  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is mandatory")
      .min(6, "Password must be at 6 char long"),
    firstName: Yup.string().required("First Name is mandatory"),
    lastName: Yup.string().required("Last Name is mandatory"),
    email: Yup.string()
      .email("Please enter a valid email format !")
      .required("Email is required please !"),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  if (isAdminAuth) {
    return <Redirect to="/dashboard" />
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="w-full max-w-md mb-4  ">
        <ChevronLeft
          onClick={() => history.goBack()}
          className=" border cursor-pointer hover:bg-gray-100 w-7 h-7 rounded-full p-1"
        />
      </div>
      <div className="bg-white   p-8 md:rounded-md shadow-main  w-full max-w-md ">
        <p className="text-2xl font-bold text-dark mb-8"> Sign Up </p>
        <form className="space-y-5" action="#" onSubmit={handleSubmit(async data => {
          setSignUpLoader(true);
          await signUpWithAPI(data, toast, setSignUpLoader, history);
        })}>
          <Input
            label="First Name"
            type="text"
            name="firstName"
            register={register}
            errors={errors}
          />
          <Input
            label="Last Name"
            type="text"
            name="lastName"
            register={register}
            errors={errors}
          />
          <Input
            label="Email"
            type="email"
            name="email"
            register={register}
            errors={errors}
          />
          <Input
            label="Password"
            type="password"
            name="password"
            register={register}
            errors={errors}
          />

          <button
            className={`btn w-full my-6 block text-center ${signUpLoader
              ? " opacity-90 cursor-not-allowed disabled:opacity-50 "
              : null
              } `}
          >
            {signUpLoader ? (
              <div
                className="w-4 h-4 border-2 loader rounded-full animate-spin mx-auto"
                role="status"
              ></div>
            ) : (
              "Sign Up"
            )}
          </button>
          <div className=" p-5 w-full bg-gray-100 rounded-md text-xs text-text text-center">
            already a Admin?{" "}
            <Link to="/SignIn" className="text-primary p-3 ">
              Sign in
            </Link>
          </div>
        </form>
      </div >
    </div >
  );
}

export default SignUp;
