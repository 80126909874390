import { Link, useHistory, useLocation } from "react-router-dom";
import { useState, useContext, useRef, useEffect } from "react";
import { ChevronDown, Menu, User, X } from "react-feather";
import { AdminContext } from "../../AdminContext";
import { useToast } from "@chakra-ui/react";
import logo from "../../assets/cyberfano.jpg";

function Navbar() {
  const location = useLocation();
  const { isAdminAuth, setIsAdminAuth } = useContext(AdminContext);
  const toast = useToast();
  let history = useHistory();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [pathName, setPathName] = useState(location.pathname);
  const [hamburgerMenu, setShowHamburgerMenu] = useState(false);
  const [showCampaignsDropdown, setShowCampaignsDropdown] = useState(false);

  const logout = () => {
    fetch(`/api/v1/sign_out`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        let data = await res.json();
        if (res.status === 200) {
          setIsAdminAuth(false);
          history.push("/");
          toast({
            title: data.message,
            status: "success",
            position: "bottom-right",
            duration: 4000,
            isClosable: true,
          });
        } else if (res.status === 201) {
          toast({
            title: data.message,
            status: "error",
            position: "bottom-right",
            duration: 4000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Something is wrong please retry!",
            status: "error",
            position: "bottom-right",
            duration: 4000,
            isClosable: true,
          });
        }
      })
      .catch((err) => {
        toast({
          title: err.message,
          status: "error",
          position: "bottom-right",
          duration: 4000,
          isClosable: true,
        });
      });
  };

  let campaignsMenu = useRef();
  let ProfileMenu = useRef();
  let HumburgerMenu = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (!campaignsMenu.current?.contains(event.target)) {
        setShowCampaignsDropdown(false);
      }
    });

    document.addEventListener("mousedown", (event) => {
      if (!ProfileMenu.current?.contains(event.target)) {
        setShowProfileMenu(false);
      }
    });

    document.addEventListener("mousedown", (event) => {
      if (!HumburgerMenu.current?.contains(event.target)) {
        setShowHamburgerMenu(false);
      }
    });
  }, []);

  return (
    <>
      {/* navbar goes here  */}
      <nav className="">
        <div className=" mx-auto py-1 px-6 md:px-10 lg:px-40 ">
          <div className="flex justify-between items-center ">
            <Link to="/">
              <img src={logo} alt="TweetEmailLogo" className="w-16 h-16"></img>
            </Link>
            <div className="hidden lg:flex items-center gap-4 select-none	">
              <div className="relative" ref={campaignsMenu}>
                <div
                  onClick={() => {
                    setShowCampaignsDropdown(!showCampaignsDropdown);
                  }}
                  className=" py-2 px-4 flex justify-between capitalize text-dark  hover:bg-opacity-50 hover:bg-gray-200 rounded"
                >
                  <p>Campaigns</p>
                  <ChevronDown />
                </div>
                {showCampaignsDropdown && (
                  <div className=" z-50  absolute left-0 bg-white border top-[calc(100% + .25rem)]  ">
                    <Link
                      to="/twitter"
                      onClick={() => {
                        setPathName("/twitter");
                        setShowCampaignsDropdown(false);
                      }}
                      className={`block w-36  py-2 px-4 capitalize text-dark  hover:bg-opacity-50 hover:bg-gray-200 rounded ${pathName === `/twitter`
                        ? ` bg-opacity-50 bg-gray-200`
                        : null
                        } `}
                    >
                      Twitter
                    </Link>
                    <Link
                      to="/email"
                      onClick={() => {
                        setPathName("/email");
                        setShowCampaignsDropdown(false);
                      }}
                      className={`block py-2 px-4 capitalize text-dark  hover:bg-opacity-50 hover:bg-gray-200 rounded ${pathName === `/email`
                        ? ` bg-opacity-50 bg-gray-200`
                        : null
                        } `}
                    >
                      Email
                    </Link>
                  </div>
                )}
              </div>
              <Link
                onClick={() => setPathName("/Archives")}
                className={`block py-2 px-4 capitalize text-dark  hover:bg-opacity-50 hover:bg-gray-200 rounded ${pathName === "/Archives" ? ` bg-opacity-50 bg-gray-200` : null
                  } `}
                to="/archives"
              >
                Archives
              </Link>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://mail.google.com/mail/u/0/#inbox?compose=CllgCJvlHXsLCrbTtCmxfLKRZZsRSGlVJssKzdCjBQCCRnDhppPvrRQlfdxPSkjLnXLqljpgBcL"
              >
                Contact Us
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://buy.stripe.com/dR62bWdOx19W04U003"
                className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg"
              >
                Donate
              </a>
            </div>

            <div className="flex space-x-4 ">
              {/* <!-- humburger button goes here --> */}
              <div className="lg:hidden flex items-center">
                <button onClick={() => setShowHamburgerMenu(!hamburgerMenu)}>
                  {!hamburgerMenu ? (
                    <Menu className="lg:hidden" />
                  ) : (
                    <X className="lg:hidden" />
                  )}
                </button>
              </div>
              <div className="flex items-center capitalize space-x-1">
                {isAdminAuth ? (
                  <div
                    ref={ProfileMenu}
                    onClick={() => setShowProfileMenu(!showProfileMenu)}
                  >
                    <div>
                      <User />
                    </div>
                    {showProfileMenu ? (
                      <div
                        onClick={() => setShowProfileMenu(!showProfileMenu)}
                        className=" absolute right-4 lg:right-36  border  my-1  bg-white rounded w-32  md:w-36 text-grey   "
                      >
                        <div>
                          <Link
                            className=" block hover:bg-gray-50 py-1 px-4 "
                            to="/dashboard"
                          >
                            Profile
                          </Link>
                        </div>
                        <div className="divider"></div>
                        <div
                          className=" cursor-pointer hover:bg-gray-50 text-red-300 py-1 px-4"
                          onClick={() => {
                            logout();
                            setShowProfileMenu(!showProfileMenu);
                          }}
                        >
                          <p>Logout</p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {/* <!-- mobile hamburgerMenu --> */}
        <div
          ref={HumburgerMenu}
          className={` py-6 px-4 lg:hidden mx-auto md:px-10 lg:px-40 ${!hamburgerMenu && "hidden"
            }`}
        >
          {[
            {
              link: "Twitter Campaign",
              url: "twitter",
            },
            {
              link: "Email Campaign",
              url: "email",
            },
            {
              link: "Archives",
              url: "archives",
            },
          ].map((item, index) => {
            return (
              <Link
                key={index}
                className="block py-2 px-4 text-sm capitalize text-dark hover:bg-gray-200"
                to={`/${item.url}`}
                onClick={() => setShowHamburgerMenu(!hamburgerMenu)}
              >
                {item.link}
              </Link>
            );
          })}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://buy.stripe.com/dR62bWdOx19W04U003"
            className="block bg-green-200 hover:bg-green-600 text-black py-2 px-4 rounded-lg"
          >
            Donate
          </a>
        </div>
      </nav>
      <div className="divider"></div>
    </>
  );
}

export default Navbar;
