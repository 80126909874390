import Paypal from "./PayPal";

export default function Payment() {
  const amount = JSON.parse(localStorage.getItem("amount"));

  const PaymentPage = () => {
    return (
      <>
        <div className="   bg-white px-4 py-6 shadow-md ">
          <div className=" mx-auto w-full max-w-2xl  ">
            <h4 className="text-2xl font-bold  text-gray-900">
              You're almost there...{" "}
            </h4>
            <p className=" text-xl text-gray-500 ">
              You will be donating ${amount} to Cyber Fano 👇🏽
            </p>
          </div>
        </div>
        <Paypal />
      </>
    );
  };

  return <PaymentPage />;
}
