import { useState } from "react";
import { Edit, Save, Trash2, X } from "react-feather";
import {
  deleteTwitterCampaign,
  fetchTwitterCampaignDetails,
  updateTwitterCampaignMetadataAPI,
} from "../../helperFunctions/APICallerFunctions";
import { useToast } from "@chakra-ui/react";
import Badge from "./Badge";
import { useHistory } from 'react-router-dom';

function CampaignCard({
  index,
  campaignMetadata,
  setRenderedCurrentCampaign,
  renderedRecentCampaign,
  setRenderedRecentCampaign,
  showToggle,
}) {
  const campaignId = campaignMetadata._id
  const [currentCampMetaData, setCurrentCampMetaData] = useState({ ...campaignMetadata })
  const history = useHistory();

  const handleChange = (e) => {
    const { name, value } = e.target
    setCurrentCampMetaData({
      ...currentCampMetaData,
      [name]: value
    })
  }

  const [isEditing, setIsEditing] = useState(false);
  const [editTwitterCampaignMetaDataId, setEditTwitterCampaignMetaDataId] = useState(null);
  const toast = useToast()

  const loadCampaign = async (campaign_id) => {
    await fetchTwitterCampaignDetails(campaign_id, setRenderedCurrentCampaign, toast,history);
  };

  const deleteCampaignMetaData = async (campaignId, index) => {
    await deleteTwitterCampaign(
      campaignId,
      index,
      toast,
      renderedRecentCampaign,
      setRenderedRecentCampaign
    );
  };


  return (
    <div

      className=" border shadow-main  my-4 p-6 space-y-4 rounded-xl flex flex-col justify-between  w-full max-w-xs "
    >
      <div
        onClick={() => {
          !showToggle && loadCampaign(campaignId)
        }}
        tabIndex="0"
        className=" m-auto w-full max-w-xs   "
      >
        <div>
          {showToggle &&
            isEditing &&
            editTwitterCampaignMetaDataId === campaignMetadata["_id"] ? (
            <input
              className="bg-gray-50 outline-none "
              value={currentCampMetaData.title}
              onChange={handleChange}
              name="title"
            />
          ) : (
            <p className=" font-bold text-dark "> {campaignMetadata?.title} </p>
          )}
          {/* <p className=" text-primary text-xs ">
            Date : {campaignMetadata["date"]}
          </p> */}
        </div>
        {showToggle &&
          isEditing &&
          editTwitterCampaignMetaDataId === campaignMetadata["_id"] ? (
          <div>
            <textarea
              name="description"
              value={currentCampMetaData.description}
              className=" bg-gray-50 text-tiny w-full font-semibold text-text "
              cols="25"
              rows="8"
              maxLength="280"
              onChange={handleChange}
            ></textarea>
          </div>
        ) : (
          <div>
            <p className="text-tiny font-semibold text-dark my-4 ">
              {campaignMetadata?.description}
            </p>
            <p className="text-2xl">{index + 2}</p>
          </div>
        )}
      </div>
      <div>
        {showToggle ? (
          <>
            {isEditing && editTwitterCampaignMetaDataId === campaignMetadata["_id"] ? (
              <div className="flex justify-between ">
                <div
                  onClick={() => {
                    updateTwitterCampaignMetadataAPI(
                      campaignMetadata._id,
                      currentCampMetaData,
                      toast,
                      renderedRecentCampaign,
                      setRenderedRecentCampaign, index, setIsEditing
                    );
                  }
                  }
                  className="flex justify-center items-center cursor-pointer hover:bg-gray-100 py-1 px-2 rounded  "
                >
                  <Save className=" text-green-500 " />
                  <p>Save</p>
                </div>
                <div
                  onClick={() => {
                    setCurrentCampMetaData(campaignMetadata)
                    setIsEditing(false)
                  }}
                  className="flex justify-center items-center cursor-pointer hover:bg-gray-100 py-1 px-2 rounded"
                >
                  <X className=" text-red-500 " />
                  <p>Cancel</p>
                </div>
              </div>
            ) : showToggle ? (
              <div className="flex justify-between">
                <div
                  onClick={() => {
                    setIsEditing(true);
                    setEditTwitterCampaignMetaDataId(campaignMetadata["_id"]);
                  }}
                  className="flex justify-center items-center cursor-pointer  hover:bg-gray-100 py-1 px-2 rounded  "
                >
                  <Edit className=" text-button " />
                  <p>Edit</p>
                </div>
                <div
                  onClick={() => {
                    deleteCampaignMetaData(campaignId, index);
                  }}
                  className="flex justify-center items-center cursor-pointer hover:bg-gray-100 py-1 px-2 rounded"
                >
                  <Trash2 className=" text-red-500 " />
                  <p>Delete</p>
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </div >
  );
}

export default CampaignCard;
