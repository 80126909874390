import hero from "../assets/hero.jpg";
import hero2 from "../assets/hero2.jpg";
import { Link } from "react-router-dom";
import Footer from "../components/Footer/Footer";

function Home() {
  return (
    <>
      <div className="px-6 lg:px-20 ">
        {/* hero section */}
        <div className="mx-auto pt-14 md:py-14 max-w-md  lg:flex justify-between lg:max-w-full lg:gap-6 ">
          <div className=" text-center max-w-2xl lg:pt-14 lg:text-left ">
            <h1 className=" text-2xl  md:text-4xl  xl:text-6xl  text-dark font-bold titleLeading ">
              Join our Campaign
            </h1>
            <p className="   text-gray-600 sm:text-lg md:text-xl lg:text-2xl  ">
              Our organization works on promoting the most forgotten and the
              extremely horrific state-sponsored AmharaGenocide in the 21st
              century.
            </p>
            <Link className=" my-4 lg:my-6 inline-block  btn" to="/twitter">
              Join Campaign
            </Link>
          </div>
          <div className="  max-w-xl my-4 flex justify-center  items-center   ">
            <img src={hero} alt="movements" />
          </div>
        </div>
        <div className=" flex flex-col gap-y-10 ">
          <div className=" lg:flex justify-between my-4 ">
            <h1 className=" font-semibold min-w-[30%]  text-xl  sm:text-2xl  lg:text-4xl my-2 ">
              who are we?
            </h1>
            <div className="   text-gray-800  text-base sm:text-lg md:text-xl lg:text-2xl ">
              {" "}
              Our organization strives to raise awareness of the 21st century's
              most horrible and mostly forgotten state-sponsored genocide, the
              Amhara Genocide. Our team works to oppose the TPLF, OLF, and its
              allies' and the Oromo-led PP government's ongoing state-sponsored
              Amhara Genocide in Ethiopia.{" "}
            </div>
          </div>

          <div>
            <h1 className="  font-semibold min-w-[30%]  text-xl  sm:text-2xl  lg:text-4xl  my-2">
              What we have done so far?
            </h1>
            <div className=" grid md:grid-cols-2 lg:grid-cols-3  md:gap-4 bg-gray-100 p-2 md:p-4 ">
              <div className=" rounded-lg  p-2 md:p-4  ">
                <p className=" text-xl  sm:text-2xl  font-semibold ">
                  Protests in DC, Seattle , Colorado{" "}
                </p>
                <p className=" text-caption font-medium text-gray-500 ">
                  Organized and led protests in DC, Seattle, and Colorado. We
                  demand an independent investigation into the ongoing
                  AmharaGenocide in Ethiopia, which is perpetrated by the PP
                  administration, TPLF, and OLF and other state-backed groups.
                </p>
              </div>
              <div className=" rounded-lg  p-2 md:p-4  ">
                <p className="text-xl  sm:text-2xl   font-semibold ">
                  Raise funds to help displaced Amhara{" "}
                </p>
                <p className=" text-caption font-medium text-gray-500 ">
                  Raised more than $120k to support Amhara Genocide victims. We
                  We helped internally displaced Amhara from Metekel, Wellega,
                  Debark, and North Bete-Amhara.
                </p>
              </div>
              <div className=" rounded-lg  p-2 md:p-4  ">
                <p className=" text-xl  sm:text-2xl   font-semibold ">
                  Coordinating Partnership{" "}
                </p>
                <p className=" text-caption font-medium text-gray-500 ">
                  Coordinated partnership with other Amhara organizations to
                  work together so that our voice will be heard loud and clear.
                  We are open to working with any Amhara organization as long as
                  they are authentic and know what they are doing.
                </p>
              </div>
            </div>
          </div>
          <div className="xl:flex justify-between items-center gap-4">
            <div className=" max-w-3xl space-y-6 ">
              <h1 className=" font-semibold min-w-[30%]  text-xl  sm:text-2xl  lg:text-4xl  my-2">
                What is our Mission
              </h1>
              <div className="   text-gray-800  text-base sm:text-lg md:text-xl lg:text-2xl ">
                Our missions is to create awareness, demand justice, debunk our
                killers' propoganda, and expose the reluctance of the
                international community to stop the ongoing state-backed
                AmharaGenocide.
              </div>
              <div className=" grid sm:grid-cols-2  max-w-3xl gap-4 ">
                <div className=" rounded-lg bg-white p-4 shadow-xl ">
                  <p className="text-xl  sm:text-2xl   leading font-semibold ">
                    Build archives for AmharaGenocide victims{" "}
                  </p>
                  <p className=" text-caption font-medium text-gray-500 ">
                    Compose archives of all the massacres that happened against
                    Amhara, in the last 35 years in Ethiopia. Please check our
                    archive page for details. We welcome anyone to share with us
                    any documents about Amhara massacres as part of the
                    AmharaGenocide that happened in the past.
                  </p>
                </div>
                <div className=" rounded-lg bg-white p-4 shadow-xl ">
                  <p className="text-xl  sm:text-2xl   leading font-semibold ">
                    Organize Protests{" "}
                  </p>
                  <p className=" text-caption font-medium text-gray-500 ">
                    Organize and lead protests in many part of the world so that
                    our voice can be heard loud. We prepare and run protests to
                    push the international organzations to do their job to stop
                    the state-sanctioned AmharaGenocide.
                  </p>
                </div>
                <div className=" rounded-lg bg-white p-4 shadow-xl ">
                  <p className="text-xl  sm:text-2xl   leading font-semibold ">
                    Twitter and Email campaigns{" "}
                  </p>
                  <p className=" text-caption font-medium text-gray-500 ">
                    Organized more than 69 Twitter campaigns with 1300+ tweets
                    and 20+ hashtags. Our team targets the perpetrators of the
                    ongoing AmharaGenocide and the resluctant international
                    organizations, including human rights watch, amnesty, and
                    western media.
                  </p>
                </div>
                <div className=" rounded-lg bg-white p-4 shadow-xl ">
                  <script
                    async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7519938092501992"
                    crossorigin="anonymous"
                  ></script>
                </div>
              </div>
            </div>
            <div className="max-w-xl flex justify-center  items-center my-4  ">
              <img src={hero2} alt="movement2" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Home;
