import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import {
	fetchCurrentTwitterCampaigns, fetchRecentTwitterCampaigns,
} from "../helperFunctions/APICallerFunctions";
import { Archive, Mail, PlusCircle, Twitter } from "react-feather";
import Archives from "./Archives";
import EmailsCampaign from "./EmailsCampaign";
import TwitterCampaignDetails from "../components/User/TwitterCampaignDetails";
import { useToast } from "@chakra-ui/react";

function Admin() {
	const toast = useToast()
	const [globalCurrentCampaign, setGlobalCurrentCampaign] = useState({});
	const [recentCampaigns, setRecentCampaigns] = useState({});

	useEffect(() => {
		fetchCurrentTwitterCampaigns(setGlobalCurrentCampaign, toast)
		fetchRecentTwitterCampaigns(setRecentCampaigns, toast)
	}, [])

	const Create = () => {
		return <div className=" grid  sm:grid-cols-2 lg:grid-cols-3 gap-4" >
			<Link to="/CreateTwitterCampaign" className="p-4 bg-gray-50 border border-dashed border-blue-500 rounded-md max-w-xl " >
				<div className=" flex justify-between ">
					<Twitter className=" text-blue-500 w-7 h-7 lg:w-10 lg:h-10  " />
					<PlusCircle className=" fill-green-600 text-green-100 w-7 h-7 lg:w-10 lg:h-10 " />
				</div>
				<p className=" text-xl lg:text-3xl" >Create Twitter Campaign</p>
			</Link>
			<Link to="/CreateEmailCampaign" className="p-4 bg-gray-50 border border-dashed border-red-500 rounded-md max-w-xl " >
				<div className=" flex justify-between ">
					<Mail className="text-red-500 w-7 h-7 lg:w-10 lg:h-10   " />
					<PlusCircle className=" fill-green-600 text-green-100 w-7 h-7 lg:w-10 lg:h-10 " />
				</div>
				<p className="text-xl lg:text-3xl" >Create Email Campaign</p>
			</Link>
			<Link to="/CreateIncident" className="p-4 bg-gray-50 border border-dashed border-green-500 rounded-md max-w-xl " >
				<div className=" flex justify-between ">
					<Archive className=" text-green-500 w-7 h-7 lg:w-10 lg:h-10  " />
					<PlusCircle className=" fill-green-600 text-green-100 w-7 h-7 lg:w-10 lg:h-10 " />
				</div>
				<p className="text-xl lg:text-3xl" >Archives</p>
			</Link>
		</div>
	}

	const AllTabs = () => {
		const [tabIndex, setTabIndex] = useState(0);

		return (

			<Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
				<TabList className=" my-4 justify-center flex gap-4 ">
					<Tab className={`${tabIndex === 0 ? `tabActive` : "tab"}`}>Twitter Campaign</Tab>
					<Tab className={`${tabIndex === 1 ? `tabActive` : "tab"}`}>Email Campaign</Tab>
					<Tab className={`${tabIndex === 2 ? `tabActive` : "tab"}`} >Archives</Tab>
				</TabList>
				<TabPanel path="/dashboard/twitter">
					<TwitterCampaignDetails currentCampaign={globalCurrentCampaign} recentCampaigns={recentCampaigns} />
				</TabPanel>
				<TabPanel>
					<EmailsCampaign />
				</TabPanel>
				<TabPanel>
					<Archives />
				</TabPanel>
			</Tabs>
		)
	}

	return (
		<div>
			<div className="px-4  md:px-6 lg:px-40">
				<Create />
				<AllTabs />
			</div>
		</div>
	);
}

export default Admin;
