import { useState, } from "react";
import { ChevronLeft } from "react-feather";
import { useHistory } from "react-router";
import { useToast } from "@chakra-ui/react";
import { createIncidentApi } from "../../helperFunctions/APICallerFunctions";


function CreateIncident() {
	const [incident, setIncident] = useState({
		title: "",
		description: "",
		location: "",
		number_of_displaced: null,
		number_of_injured: null,
		number_of_killed: null,
		date_of_incident: ""
	});

	const toast = useToast()
	const [errors, setErrors] = useState({});

	useState(false);
	let history = useHistory();
	const characterLimit = {
		title: 90,
		description: 160,
	};

	const changeIncidentDetails = (e) => {
		const { name, value } = e.target;
		setIncident({
			...incident,
			[name]: value,
		});
	};

	return (
		<>
			<div className="w-10/12 max-w-md m-auto ">
				<ChevronLeft
					onClick={() => history.goBack()}
					className=" border my-6 cursor-pointer hover:bg-gray-100 w-7 h-7 rounded-full p-1"
				/>
			</div>
			<div className="flex items-center justify-center  max-w-3xl m-auto">
				<div className="bg-white px-8  max-w-lg  w-full ">
					<div>
						<form className="space-y-4 ">
							<div>
								<div
									className={`border-2 bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 ${errors.title && "border-red-400 focus:border-red-500"
										}`}
								>
									<div className="flex justify-between">
										<label
											className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
											htmlFor="campaignTitle"
										>
											Event Title?
										</label>
										<span
											className={`text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 ${incident.title.length > characterLimit.title
												? "text-red-500"
												: null
												}`}
										>
											{`${incident.title.length}/ ${characterLimit.title} `}
										</span>
									</div>

									<input
										className="w-full px-2 pb-1.5 bg-gray-100 outline-none  font-light rounded-md"
										type="text"
										id="campaignTitle"
										name="title"
										value={incident["title"]}
										onChange={changeIncidentDetails}
										required
									/>
								</div>
								{errors.title && (
									<p className=" text-red-400  text-xs font-medium	 ">
										{errors.title}
									</p>
								)}
							</div>
							<div>
								<div
									className={`border-2 bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 ${errors.description && "border-red-400 focus:border-red-500"
										}`}
								>
									<div className="flex justify-between">
										<label
											className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
											htmlFor="description"
										>
											Add Description about your Event!
										</label>
										<span
											className={`text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 ${incident.description.length > characterLimit.description
												? "text-red-500"
												: null
												}`}
										>
											{`${incident.description.length}/ ${characterLimit.description} `}
										</span>
									</div>

									<textarea
										id="description"
										name="description"
										className="w-full px-2 pb-1.5 bg-gray-100 outline-none focus:border-blue-500 font-light rounded-md"
										cols="25"
										rows="2"
										value={incident["description"]}
										onChange={changeIncidentDetails}
									></textarea>
								</div>
								{errors.description && (
									<p className=" text-red-400  text-xs font-medium	 ">
										{errors.description}
									</p>
								)}
							</div>
							<div>
								<div
									className={`border-2 bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 ${errors.location && "border-red-400 focus:border-red-500"
										}`}
								>
									<div className="flex justify-between">
										<label
											className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
											htmlFor="location"
										>
											Incident location?
										</label>
									</div>

									<input
										className="w-full px-2 pb-1.5 bg-gray-100 outline-none  font-light rounded-md"
										type="text"
										id="location"
										name="location"
										value={incident["location"]}
										onChange={changeIncidentDetails}
										required
									/>
								</div>
								{errors.location && (
									<p className=" text-red-400  text-xs font-medium	 ">
										{errors.location}
									</p>
								)}
							</div>
							<div>
								<div
									className={`border-2 bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 ${errors.title && "border-red-400 focus:border-red-500"
										}`}
								>
									<div className="flex justify-between">
										<label
											className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
											htmlFor="date_of_incident"
										>
											Date of Incident?
										</label>
									</div>

									<input
										className="w-full px-2 pb-1.5 bg-gray-100 outline-none  font-light rounded-md"
										type="date"
										id="date_of_incident"
										name="date_of_incident"
										value={incident["date_of_incident"]}
										onChange={changeIncidentDetails}
										required
									/>
								</div>
								{errors.date_of_incident && (
									<p className=" text-red-400  text-xs font-medium	 ">
										{errors.date_of_incident}
									</p>
								)}
							</div>
							<div className="grid grid-cols-3 gap-2" >
								<div>
									<div
										className={`border-2 bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 ${errors.number_of_displaced && "border-red-400 focus:border-red-500"
											}`}
									>
										<div className="flex justify-between">
											<label
												className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
												htmlFor="number_of_displaced"
											>
												Displaced ?
											</label>

										</div>

										<input
											className="w-full px-2 pb-1.5 bg-gray-100 outline-none  font-light rounded-md"
											type="number"
											id="number_of_displaced"
											name="number_of_displaced"
											onChange={changeIncidentDetails}
											value={incident["number_of_displaced"]}
											required
										/>
									</div>
									{errors.number_of_displaced && (
										<p className=" text-red-400  text-xs font-medium	 ">
											{errors.number_of_displaced}
										</p>
									)}
								</div>
								<div>
									<div
										className={`border-2 bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 ${errors.number_of_injured && "border-red-400 focus:border-red-500"
											}`}
									>
										<div className="flex justify-between">
											<label
												className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
												htmlFor="number_of_injured"
											>
												Injured ?
											</label>

										</div>

										<input
											className="w-full px-2 pb-1.5 bg-gray-100 outline-none  font-light rounded-md"
											type="number"
											id="number_of_injured"
											name="number_of_injured"
											value={incident["number_of_injured"]}
											onChange={changeIncidentDetails}
											required
										/>
									</div>
									{errors.number_of_injured && (
										<p className=" text-red-400  text-xs font-medium	 ">
											{errors.number_of_injured}
										</p>
									)}
								</div>
								<div>
									<div
										className={`border-2 bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 ${errors.number_of_killed && "border-red-400 focus:border-red-500"
											}`}
									>
										<div className="flex justify-between">
											<label
												className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
												htmlFor="number_of_killed"
											>
												Killed ?
											</label>
										</div>

										<input
											className="w-full px-2 pb-1.5 bg-gray-100 outline-none  font-light rounded-md"
											type="number"
											id="number_of_killed"
											name="number_of_killed"
											value={incident["number_of_killed"]}
											onChange={changeIncidentDetails}
											required
										/>
									</div>
									{errors.number_of_killed && (
										<p className=" text-red-400  text-xs font-medium	 ">
											{errors.number_of_killed}
										</p>
									)}
								</div>
							</div>
						</form>
					</div>
					<button
						className="   text-text capitalize  border-2 border-gray-200 my-4  py-2 px-4  rounded font-semibold justify-self-end "
						onClick={() => createIncidentApi(incident, toast, history)}
					>
						Create Incident
					</button>
				</div>
			</div>
		</>
	);
}

export default CreateIncident;
