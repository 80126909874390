import { useToast } from "@chakra-ui/react";
import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useHistory } from "react-router-dom";

export default function Paypal() {
  const toast = useToast();
  const amount = JSON.parse(localStorage.getItem("amount"));
  let history = useHistory();
  let paypal_client_id = process.env.REACT_APP_PAYPAL_CLIENT_ID;
  console.log(paypal_client_id);

  return (
    <div className=" max-w-md mx-auto w-5/6 my-8 ">
      <PayPalScriptProvider options={{ "client-id": paypal_client_id }}>
        <PayPalButtons
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: amount,
                  },
                },
              ],
            });
          }}
          onApprove={async (data, actions) => {
            const details = await actions.order.capture();
            const name = details.payer.name.given_name;
            toast({
              title: `Thank you, ${name} for supporting Cyber Fano.`,
              status: "success",
              position: "bottom-right",
              duration: 4000,
              isClosable: true,
            });
            history.goBack();
          }}
        />
      </PayPalScriptProvider>
    </div>
  );
}
