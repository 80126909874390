import { useState } from "react";
import { PlusCircle, Trash2 } from "react-feather";

function HandleASingleTweetCampaign({
  tweet,
  idx,
  last,
  updateTheCurrentTweet,
  newTweetsListLength,
  deleteTweet,
  addMoreTweets,
  submitted,
}) {
  const maxChar = 280;
  const urlCharCount = 23;

  const countTrueTweetCharCount = (tweet) => {
    if (!tweet) {
      return 0;
    }
    if (tweet.includes("http")) {
      return tweet.split("http")[0].length + urlCharCount;
    }

    return tweet.length;
  };

  const [tweetLength, setTweetLength] = useState(0);
  return (
    <>
      <div
        className={`border-2 my-2  bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 ${
          newTweetsListLength > 1 && tweet.body.length === 0
            ? "border-red-500 focus-within:border-red-500"
            : null
        } ${
          submitted && tweet.body.length === 0
            ? "border-red-500 focus-within:border-red-500"
            : null
        } 
      ${
        countTrueTweetCharCount(tweet.body) > maxChar
          ? "border-red-500 focus-within:border-red-500"
          : null
      }`}
      >
        <div className="flex justify-between">
          <label
            id="tweetBody"
            className=" text-xs select-none text-text  font-semibold placeholder-gray-gray4 px-2 pt-1.5"
          >
            Add your message!
          </label>
          <label
            className={`text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 ${
              tweetLength > maxChar ? "text-red-500" : null
            }`}
          >
            {tweetLength}/ {maxChar}
          </label>
        </div>
        <div
          className={`  flex ${idx === last ? "relative" : null}  `}
          key={tweet.id}
        >
          <textarea
            value={tweet.body}
            cols="25"
            rows="7"
            className={`w-full px-2 pb-1.5 bg-gray-100 outline-none focus:border-blue-500 font-light rounded-md `}
            onChange={(e) => {
              setTweetLength(countTrueTweetCharCount(e.target.value));
              updateTheCurrentTweet(e.target.value, tweet.id, idx);
            }}
          ></textarea>

          <div>
            {newTweetsListLength > 1 ? (
              <Trash2
                onClick={() => deleteTweet(tweet.id)}
                className=" absolute my-2 sm:ml-5 cursor-pointer  text-red-500 "
              />
            ) : null}
          </div>
          {idx === last ? (
            <div>
              <PlusCircle
                onClick={() => addMoreTweets()}
                className=" absolute sm:-right-10 bottom-1  cursor-pointer text-green-500"
              />
            </div>
          ) : null}
        </div>
      </div>
      <p className=" text-red-400  text-xs font-medium">
        {(tweet.body.length === 0 || !tweet.body) && submitted
          ? `You must enter your Tweet.`
          : null}
      </p>
      <p className=" text-red-400  text-xs font-medium">
        {countTrueTweetCharCount(tweet.body) > maxChar ||
        (countTrueTweetCharCount(tweet.body) > maxChar && submitted)
          ? `Character limit for a tweet is 280 characters`
          : null}
      </p>
    </>
  );
}

export default HandleASingleTweetCampaign;
