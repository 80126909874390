import { Tabs, TabPanel } from "react-tabs";
import { useState, useEffect } from "react";
import { ChevronLeft } from "react-feather";
import { useHistory } from "react-router";
import { createEmailCampaign } from "../helperFunctions/APICallerFunctions";
import { AnimatePresence, motion } from "framer-motion";
import { X } from "react-feather";
import validator from "validator";
import { useToast } from "@chakra-ui/react";

function CreateEmailCampaign() {
	const toast = useToast()
	const [campaign, setCampaign] = useState({
		campaign_title: "",
		campaign_description: "",
	});
	const [email, setEmail] = useState(
		{
			cc: [],
			to: [],
			subject: "",
			body: "",
		},
	);

	const handleEmailChange = (e) => {
		setEmail({
			...email,
			[e.target.name]: e.target.value,
		});
	}

	const [submitted, setIsSubmitted] = useState();
	const [errors, setErrors] = useState({});
	const [tabIndex, setTabIndex] = useState(0);
	const [uploadingTweetsLoader, setUploadingTweetsLoader] =
		useState(false);
	let history = useHistory();
	const characterLimit = {
		title: 60,
		description: 160,
	};

	const [page, setPage] = useState(0);

	function validateCampaignDetails() {
		let errors = {};
		if (!campaign.campaign_title) {
			errors.campaign_title = "Campaign Title is Required";
		}
		if (campaign.campaign_title.length > characterLimit.title) {
			errors.campaign_title = `Please try making it less than ${characterLimit.title} characters.`;
		}
		if (!campaign.campaign_description) {
			errors.campaign_description = "Campaign campaign_description is Required";
		}
		if (campaign.campaign_description.length > characterLimit.description) {
			errors.campaign_description = `Please try making it less than ${characterLimit.description} characters.`;
		}
		setErrors(errors);
	}


	const changeCampaignTitleAndDescription = (e) => {
		const { name, value } = e.target;
		setCampaign({
			...campaign,
			[name]: value,
		});
	};




	const goToNextPage = () => {
		if (page === 1) {
			return;
		} else {
			setPage(1);
		}
	};

	const goToPrevPage = () => {
		if (page === 0) {
			return;
		} else {
			setPage((page) => page - 1);
		}
	};

	const [currentEmailObj, setCurrentEmailObj] = useState(email);
	const [toEmailAddressList, setToEmailAddressList] = useState(email.to);
	const [ccEmailAddressList, setCcEmailAddressList] = useState(email.cc);
	const [currentToEmailAddress, setCurrentToEmailAddress] = useState("");
	const [currentCcEmailAddress, setCurrentCcEmailAddress] = useState("");
	const [toError, setToError] = useState(false);
	const [ccerror, setCcError] = useState(false);

	useEffect(() => {
		const timeOut = setTimeout(() => {
			setToError(false);
			setCcError(false);
		}, 3000);
		return () => clearTimeout(timeOut);
	}, [toError, ccerror]);


	const RunCampaign = () => {
		return (
			<div>
				<Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>

					<TabPanel>
						<div className="flex flex-col  ">
							<div className="my-2">
								<div
									key={email.id}
								>
									<div className="">
										<div
											className={`border-2 my-2  bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4  ${submitted && email.subject.length === 0
												? "border-red-500 focus-within:border-red-500"
												: null
												}
               `}
										>
											<label
												className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
												htmlFor="subject"
											>
												Add Subject of Email
											</label>
											<input
												type="text"
												name="subject"
												onChange={handleEmailChange}
												value={email.subject}
												className="w-full px-2 pb-1.5 bg-gray-100 outline-none focus:border-blue-500 font-light rounded-md"
											/>
										</div>
										<p className=" text-red-400  text-xs font-medium">
											{(email.subject.length === 0 || !email.subject) && submitted
												? `You must enter your email subject.`
												: null}
										</p>
										<div className="grid gap-2   grid-flow-row sm:grid-cols-2 my-4 ">
											<AnimatePresence
												exitBeforeEnter={true}
											>
												{currentEmailObj.to.map((toemail, index) => {
													return (
														<motion.div
															key={index}
															exit={{
																opacity: 0,
															}}
														>
															{toemail && toemail !== " " ? (
																<div className="addEmails  transform active:scale-95  px-2 py-1 my-2 bg-gray-100 rounded-lg flex items-center justify-between space-x-2 ">
																	<div>{toemail}</div>
																	<X
																		onClick={() => {
																			const filteredToEmailList = currentEmailObj.to;
																			filteredToEmailList.splice(index, 1);
																			setToEmailAddressList(filteredToEmailList);
																			email.to = filteredToEmailList;
																			setCurrentEmailObj({
																				...currentEmailObj,
																				to: filteredToEmailList,
																			});
																		}}
																		className=" w-6 h-6 border p-1 cursor-pointer hover:bg-gray-50 rounded-full"
																	/>
																</div>
															) : null}
														</motion.div>
													);
												})}
											</AnimatePresence>
										</div>
										<div>
											<div
												className={` border-2 my-4 bg-gray-100   rounded transition duration-150 ease-in-out   ${toError ? " border-2 border-red-500" : null
													}  ${submitted && email.body.length === 0
														? "border-red-500 focus-within:border-red-500"
														: null
													}`}
											>
												<label
													className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
													htmlFor="toEmailAddress"
												>
													Enter to Email Address
												</label>
												<input
													className={`w-full px-2 pb-1.5 bg-gray-100 outline-none  font-light rounded-md `}
													type="text"
													id="toEmailAddress"
													name="to"
													placeholder="Type or paste email address and press ENTER"
													onKeyDown={(e) => {
														if (e.key === "Enter") {
															if (
																!e.target.value ||
																e.target.value === " " ||
																!validator.isEmail(e.target.value)
															) {
																setToError(true);
															} else {
																toEmailAddressList.push(currentToEmailAddress);
																currentEmailObj.to = toEmailAddressList;
																setCurrentEmailObj(currentEmailObj);
															}
															setCurrentToEmailAddress("");
														}
													}}
													onChange={(e) => setCurrentToEmailAddress(e.target.value)}
													value={currentToEmailAddress}
												/>
											</div>
											{currentToEmailAddress ? (
												<p
													onClick={() => {
														if (
															!currentToEmailAddress ||
															currentToEmailAddress === " " ||
															!validator.isEmail(currentToEmailAddress)
														) {
															setToError(true);
														} else {
															toEmailAddressList.push(currentToEmailAddress);
															currentEmailObj.to = toEmailAddressList;
															setCurrentEmailObj(currentEmailObj);
														}
														setCurrentToEmailAddress("");
													}}
													className="border-2 cursor-pointer inline-block px-2 rounded-md "
												>
													Add recipient : {currentToEmailAddress}
												</p>
											) : null}
										</div>
										<p className=" text-red-400  text-xs font-medium">
											{(email.to.length === 0 || !email.to) && submitted
												? `You must enter your to email.`
												: null}
										</p>

										<div className="grid gap-2  grid-flow-row sm:grid-cols-2 my-4 ">
											<AnimatePresence exitBeforeEnter={true}>
												{currentEmailObj.cc.map((currentCc, index) => {
													return (
														<motion.div
															key={index}
															exit={{
																opacity: 0,
															}}
														>
															{currentCc ? (
																<div className="addEmails transform active:scale-95  px-2 py-1 my-2 bg-gray-100 rounded-lg flex items-center justify-between space-x-2 ">
																	<div className="">{currentCc}</div>
																	<X
																		onClick={() => {
																			const filteredCcEmailList = currentEmailObj.cc;
																			filteredCcEmailList.splice(index, 1);
																			setCcEmailAddressList(filteredCcEmailList);
																			email.cc = filteredCcEmailList;
																			setCurrentEmailObj({
																				...currentEmailObj,
																				cc: filteredCcEmailList,
																			});
																		}}
																		className=" w-6 h-6 border p-1 cursor-pointer hover:bg-gray-50 rounded-full"
																	/>
																</div>
															) : null}
														</motion.div>
													);
												})}
											</AnimatePresence>
										</div>
										<div>
											<div
												className={` border-2 my-4 bg-gray-100  rounded transition duration-150 ease-in-out   ${ccerror ? " border-2 border-red-500" : null
													}`}
											>
												<label
													className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
													htmlFor="ccEmailAddress"
												>
													Enter cc Email Address
												</label>
												<input
													className="w-full px-2 pb-1.5 bg-gray-100 outline-none focus:border-blue-500 font-light rounded-md"
													type="text"
													id="ccEmailAddress"
													name="cc"
													placeholder="Type or paste CC email address and press ENTER"
													onKeyDown={(e) => {
														if (e.key === "Enter") {
															if (
																!e.target.value ||
																e.target.value === " " ||
																!validator.isEmail(e.target.value)
															) {
																setCcError(true);
															} else {
																ccEmailAddressList.push(currentCcEmailAddress);
																currentEmailObj.cc = ccEmailAddressList;
																setCurrentEmailObj(currentEmailObj);
															}

															setCurrentCcEmailAddress("");
														}
													}}
													onChange={(e) => {
														setCurrentCcEmailAddress(e.target.value);
													}}
													value={currentCcEmailAddress}
												/>
											</div>
											{currentCcEmailAddress ? (
												<p
													onClick={() => {
														if (
															!currentCcEmailAddress ||
															currentCcEmailAddress === " " ||
															!validator.isEmail(currentCcEmailAddress)
														) {
															setToError(true);
														} else {
															ccEmailAddressList.push(currentCcEmailAddress);
															currentEmailObj.cc = ccEmailAddressList;
															setCurrentEmailObj(currentEmailObj);
														}
														setCurrentCcEmailAddress("");
													}}
													className="border-2 cursor-pointer inline-block px-2 rounded-md "
												>
													Add recipient : {currentCcEmailAddress}
												</p>
											) : null}
										</div>

										<div
											className={`border-2 my-2  bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4  ${submitted && email.body.length === 0
												? "border-red-500 focus-within:border-red-500"
												: null
												}
               `}
										>
											<label className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 ">
												Enter Email
											</label>
											<textarea
												className="w-full px-2 pb-1.5 bg-gray-100 outline-none focus:border-blue-500 font-light rounded-md"
												cols="25"
												rows="6"
												name="body"
												onChange={handleEmailChange}
												value={email.body}
											></textarea>
										</div>
										<p className=" text-red-400  text-xs font-medium">
											{(email.body.length === 0 || !email.body) && submitted
												? `You must enter your Email.`
												: null}
										</p>
									</div>
								</div>
							</div>
						</div>
					</TabPanel>
				</Tabs>
				<button
					type="button"
					onClick={
						() => createEmailCampaign(
							{ ...campaign, ...email },
							history,
							toast
						)
					}
					className={`btn w-full text-center ${uploadingTweetsLoader
						? " opacity-90 cursor-not-allowed disabled:opacity-50 "
						: null
						}`}
				>
					{uploadingTweetsLoader ? (
						<div
							className="w-4 h-4 border-2 loader rounded-full animate-spin mx-auto"
							role="status"
						></div>
					) : (
						"Share"
					)}
				</button>
			</div >
		);
	};

	const SelectCampaign = () => {
		return (
			<div>
				<form className="space-y-4 ">
					<div>
						<div
							className={`border-2 bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 ${errors.campaign_title && "border-red-400 focus:border-red-500"
								}`}
						>
							<div className="flex justify-between">
								<label
									className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
									htmlFor="campaignTitle"
								>
									Campaign Title?
								</label>
								<span
									className={`text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 ${campaign.campaign_title.length > characterLimit.title
										? "text-red-500"
										: null
										}`}
								>
									{`${campaign.campaign_title.length}/ ${characterLimit.title} `}
								</span>
							</div>

							<input
								className="w-full px-2 pb-1.5 bg-gray-100 outline-none  font-light rounded-md"
								type="text"
								id="campaignTitle"
								name="campaign_title"
								value={campaign["campaign_title"]}
								onChange={changeCampaignTitleAndDescription}
								required
							/>
						</div>
						{errors.campaign_title && (
							<p className=" text-red-400  text-xs font-medium	 ">
								{errors.campaign_title}
							</p>
						)}
					</div>
					<div>
						<div
							className={`border-2 bg-gray-100  rounded transition duration-150 ease-in-out focus-within:border-blue-500 border-gray-gray4 ${errors.campaign_description && "border-red-400 focus:border-red-500"
								}`}
						>
							<div className="flex justify-between">
								<label
									className="text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 "
									htmlFor="campaign_description"
								>
									Add Description about your Campaign!
								</label>
								<span
									className={`text-xs select-none text-text font-semibold placeholder-gray-gray4 px-2 pt-1.5 ${campaign.campaign_description.length > characterLimit.description
										? "text-red-500"
										: null
										}`}
								>
									{`${campaign.campaign_description.length}/ ${characterLimit.description} `}
								</span>
							</div>
							<textarea
								id="campaign_description"
								name="campaign_description"
								className="w-full px-2 pb-1.5 bg-gray-100 outline-none focus:border-blue-500 font-light rounded-md"
								cols="25"
								rows="2"
								value={campaign["campaign_description"]}
								onChange={changeCampaignTitleAndDescription}
							></textarea>
						</div>
						{errors.campaign_description && (
							<p className=" text-red-400  text-xs font-medium	 ">
								{errors.campaign_description}
							</p>
						)}
					</div>
				</form>
			</div>
		);
	};

	return (
		<>
			<div className="w-10/12 max-w-md m-auto ">
				<ChevronLeft
					onClick={() => history.goBack()}
					className=" border my-6 cursor-pointer hover:bg-gray-100 w-7 h-7 rounded-full p-1"
				/>
			</div>
			<div className="flex items-center justify-center  max-w-3xl m-auto">
				<div className="bg-white px-8  max-w-lg  w-full ">
					<div className="flex justify-between my-4 ">
						<div className="flex justify-between items-center gap-2 ">
							<span
								className={` ${page === 0 || page === 1 ? " bg-blue-800 " : "bg-blue-100 "
									} py-1 px-3 rounded-full  `}
							>
								1
							</span>
							<div className=" text-dark text-tiny ">
								Select
								<p className=" hidden md:inline-block px-1">Campaign</p>
							</div>
						</div>
						<div className="flex justify-between items-center gap-2 ">
							<span
								className={` ${page === 1 ? " bg-blue-800 " : "bg-blue-100 "
									}  py-1 px-3 rounded-full bg-blue-100  `}
							>
								2
							</span>
							<div className=" text-dark text-tiny ">
								Create
								<span className=" hidden md:inline-block px-1">Campaign</span>
							</div>
						</div>
					</div>
					{page === 1 && <div className="divider"></div>}
					{page === 0 ? SelectCampaign() : null}
					{page === 1 ? RunCampaign() : null}
					<button
						className="  text-text capitalize  border-2 border-gray-200 my-4  py-2 px-4  rounded font-semibold justify-self-end "
						onClick={() => {
							if (page === 0) {
								if (
									!campaign.campaign_title ||
									!campaign.campaign_description ||
									campaign.campaign_description.length > characterLimit.description ||
									campaign.campaign_title.length > characterLimit.title
								) {
									validateCampaignDetails();
								} else {
									goToNextPage();
								}
							} else {
								goToPrevPage();
							}
						}}
					>
						{page === 0 ? "next" : "prev"}
					</button>
				</div>
			</div>
		</>
	);
}

export default CreateEmailCampaign;
