function Badge({ title, text, bold }) {
  return (
    <div className=" text-xs md:text-base my-4  bg-gray-100 rounded p-2  w-max">
      {title && (
        <p>
          <span className="font-bold text-dark ">{title}</span>
        </p>
      )}
      {text && (
        <p className={` text-dark ${bold ? " font-bold " : null} `}>{text}</p>
      )}
    </div>
  );
}

export default Badge;
